import BuyersFilterContent from "./Buyers";
import ContactInformationFilterContent from "./ContactInformation";
import ContactNameFilterContent from "./ContactName";
import JobAndSeniorityFilterContent from "./JobAndSeniority";

export const contactFilterContents = {
  jobTitleAndSeniority: JobAndSeniorityFilterContent,
  contactInformation: ContactInformationFilterContent,
  buyers: BuyersFilterContent,
  contactName: ContactNameFilterContent,
};
