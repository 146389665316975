import * as React from "react";
import { App, Button } from "antd5";
import classNames from "classnames";

import { FeatureType } from "components/paywall/paywallUtils";
import { assertCurrentUser } from "lib/currentUser";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { EventData } from "lib/tracking";
import { ActionableBanner } from "./ActionableBanner";
import PaywallCTAModal from "./PaywallCTAModal";
import { SubscriptionPlans } from "./SubscriptionPlans";
import { SubscriptionPlansDetails } from "./SubscriptionPlansDetails";

import css from "./Subscription.module.scss";

type Props = {
  className?: string;
  triggerBookADemoForm?: (value: boolean) => void;
  trackingData?: EventData;
};

function Subscription({ className, triggerBookADemoForm, trackingData }: Props): JSX.Element {
  const [paywallCTAModalOpen, setPaywallCTAModalOpen] = React.useState(false);
  const currentPlanisFreemium = assertCurrentUser().subscription === "freemium";
  const { mutate: pqlActioned } = usePqlActioned(undefined, trackingData);
  const { message } = App.useApp();

  const handleContactYourCSMButton = () => {
    pqlActioned({
      actionType: "Speak to your CSM",
      pageUrl: window.location.href,
      dataType: trackingData?.["Data type"] as FeatureType,
    });

    void message.success("Your Customer Success Manager has been contacted.");
  };

  return (
    <div className={classNames(css.container, className)}>
      <div className={css.heading}>
        <h1>
          A plan that's right for your team<span className={css.dot}>.</span>
        </h1>
        <p>Fit for purpose plans to drive sales productivity in the public sector market.</p>
        {currentPlanisFreemium ? (
          <Button
            type="primary"
            className={css.bookADemoButton}
            onClick={() => {
              pqlActioned({
                actionType: "Book a demo in-app",
                pageUrl: window.location.href,
                dataType: trackingData?.["Data type"] as FeatureType,
              });

              // In settings we want to open a new modal (PaywallCTAModal) to show the book a demo form
              // Anywhere else we want to display the form and plan details in the same modal
              triggerBookADemoForm ? triggerBookADemoForm(true) : setPaywallCTAModalOpen(true);
            }}
          >
            Book a demo
          </Button>
        ) : (
          <Button
            type="primary"
            className={css.speakToYourCSMButton}
            onClick={handleContactYourCSMButton}
          >
            Speak to your Customer Success Manager
          </Button>
        )}
      </div>

      <SubscriptionPlans />
      <SubscriptionPlansDetails />
      {currentPlanisFreemium && (
        <ActionableBanner
          setBookADemoModalOpen={
            triggerBookADemoForm ? triggerBookADemoForm : setPaywallCTAModalOpen
          }
        />
      )}
      <PaywallCTAModal
        isOpen={paywallCTAModalOpen}
        onClose={() => setPaywallCTAModalOpen(false)}
        showBookADemoForm={true}
        featureType={trackingData?.["Data type"] as FeatureType}
      />
    </div>
  );
}

export default Subscription;
