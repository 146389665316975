/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordMatch } from './RecordMatch';
import {
    RecordMatchFromJSON,
    RecordMatchFromJSONTyped,
    RecordMatchToJSON,
} from './RecordMatch';

/**
 * 
 * @export
 * @interface QueryResult
 */
export interface QueryResult {
    /**
     * 
     * @type {Array<RecordMatch>}
     * @memberof QueryResult
     */
    matchedRecords: Array<RecordMatch>;
    /**
     * 
     * @type {Array<any>}
     * @memberof QueryResult
     */
    cursor: Array<any>;
}

/**
 * Check if a given object implements the QueryResult interface.
 */
export function instanceOfQueryResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "matchedRecords" in value;
    isInstance = isInstance && "cursor" in value;

    return isInstance;
}

export function QueryResultFromJSON(json: any): QueryResult {
    return QueryResultFromJSONTyped(json, false);
}

export function QueryResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matchedRecords': ((json['matched_records'] as Array<any>).map(RecordMatchFromJSON)),
        'cursor': json['cursor'],
    };
}

export function QueryResultToJSON(value?: QueryResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matched_records': ((value.matchedRecords as Array<any>).map(RecordMatchToJSON)),
        'cursor': value.cursor,
    };
}

