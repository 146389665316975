import React from "react";
import { Control } from "react-hook-form";

import { Input } from "components/form_components/Inputs";
import { ContactFiltersComplete } from "../FilterForm";

import css from "./SharedFilterGroups.module.scss";

export interface ContactNameContentProps {
  control: Control<ContactFiltersComplete, unknown>;
}

function ContactNameFilterContent({ control }: ContactNameContentProps) {
  return (
    <div className={css.filterSection} aria-label="Contact name filters">
      <Input name="firstName" label="First name" control={control} placeholder="Search names" />
      <Input name="lastName" label="Last name" control={control} placeholder="Search names" />
    </div>
  );
}

export default ContactNameFilterContent;
