/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForwardGeolocationRequest
 */
export interface ForwardGeolocationRequest {
    /**
     * 
     * @type {string}
     * @memberof ForwardGeolocationRequest
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardGeolocationRequest
     */
    countryCode: string;
}

/**
 * Check if a given object implements the ForwardGeolocationRequest interface.
 */
export function instanceOfForwardGeolocationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

export function ForwardGeolocationRequestFromJSON(json: any): ForwardGeolocationRequest {
    return ForwardGeolocationRequestFromJSONTyped(json, false);
}

export function ForwardGeolocationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwardGeolocationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'countryCode': json['country_code'],
    };
}

export function ForwardGeolocationRequestToJSON(value?: ForwardGeolocationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'country_code': value.countryCode,
    };
}

