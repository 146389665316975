/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewPromptResponse
 */
export interface PreviewPromptResponse {
    /**
     * 
     * @type {string}
     * @memberof PreviewPromptResponse
     */
    renderedPrompt: string;
}

/**
 * Check if a given object implements the PreviewPromptResponse interface.
 */
export function instanceOfPreviewPromptResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "renderedPrompt" in value;

    return isInstance;
}

export function PreviewPromptResponseFromJSON(json: any): PreviewPromptResponse {
    return PreviewPromptResponseFromJSONTyped(json, false);
}

export function PreviewPromptResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewPromptResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'renderedPrompt': json['rendered_prompt'],
    };
}

export function PreviewPromptResponseToJSON(value?: PreviewPromptResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rendered_prompt': value.renderedPrompt,
    };
}

