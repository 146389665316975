import * as React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import { produce } from "immer";
import { v4 as uuidv4 } from "uuid";

import TextButton from "components/actions/TextButton";
import StotlesVerifiedIcon from "lib/core_components/StotlesVerifiedIcon";
import { useDialogManager } from "lib/providers/DialogManager";
import { NewLabel } from "./OrgLabels";
import BuyerSearchModal from "./OrgSearchModal";
import TextInputModal from "./TextInputModal";
import { OrgToBeCreated, OrgWithStats } from "./types";

import css from "./DistinctJourney.module.scss";

type Props = {
  onBuyersSelect: (
    buyers: OrgWithStats[] | OrgToBeCreated[],
    buyerType: "selectedDistinctBuyers" | "newDistinctBuyers",
  ) => void;
  selectedDistinctBuyers: OrgWithStats[];
  newBuyers: OrgToBeCreated[];
  addNewBuyer: (b: OrgToBeCreated) => void;
  removeNewBuyer: (guid: string) => void;
  candidate: OrgWithStats;
  orgPrimaryRole: "Buyer" | "Supplier";
};

function DistinctJourney({
  onBuyersSelect,
  selectedDistinctBuyers,
  newBuyers,
  addNewBuyer,
  removeNewBuyer,
  candidate,
  orgPrimaryRole,
}: Props): JSX.Element {
  const dialogManager = useDialogManager();

  const removeDistinctBuyer = React.useCallback(
    (guid: string) => {
      onBuyersSelect(
        produce(selectedDistinctBuyers, (selected) => {
          const idx = selected.findIndex((i) => i.guid === guid);
          selected.splice(idx, 1);
        }),
        "selectedDistinctBuyers",
      );
    },
    [onBuyersSelect, selectedDistinctBuyers],
  );

  const removeNew = React.useCallback(
    (guid: string) => {
      onBuyersSelect(
        produce(newBuyers, (newBSelected) => {
          const idx = newBSelected.findIndex((i) => i.guid === guid);
          newBSelected.splice(idx, 1);
        }),
        "newDistinctBuyers",
      );
      removeNewBuyer(guid);
    },
    [onBuyersSelect, newBuyers, removeNewBuyer],
  );

  const openNewBuyerModal = React.useCallback(() => {
    void dialogManager.openDialog(TextInputModal, {
      onSubmit: (name: string) => {
        const newB = { name, guid: uuidv4() };
        onBuyersSelect(
          produce(newBuyers, (selected) => {
            selected.push(newB);
          }),
          "newDistinctBuyers",
        );
        addNewBuyer(newB);
      },
      modalTitle: "Create new buyer (already exists in modal, don’t change)",
      title: "Type the buyer name that should exist ",
      description: (
        <>
          Are you sure the buyer isn't in the existing list? Make sure to check for alternate names.
          You can do this by:
          <br />
          <ul>
            <li>
              Searching for a broader term in case of different variations (e.g., search{" "}
              <i>Camden</i> instead of <i>London borough of Camden</i>)
            </li>
            <li>
              Googling the typed name (e.g., Googling "PCC for Cheshire" shows "Police and Crime
              Commissioner for Cheshire")
            </li>
          </ul>
        </>
      ),
    });
  }, [dialogManager, newBuyers, onBuyersSelect, addNewBuyer]);

  const renderBuyerSearchFooter = React.useCallback(
    (onClose) => {
      return (
        <div>
          <TextButton
            onClick={() => {
              openNewBuyerModal();
              onClose();
            }}
          >
            Buyer doesn't exist in list
          </TextButton>
        </div>
      );
    },
    [openNewBuyerModal],
  );

  return (
    <div className={css.distinctJourney}>
      <h3>Find distinct buyers</h3>
      <div className={css.buttonContainer}>
        <Button
          onClick={() => {
            void dialogManager.openDialog(BuyerSearchModal, {
              onSelectBuyer: (b) =>
                onBuyersSelect(
                  produce(selectedDistinctBuyers, (selected) => {
                    selected.push(b);
                  }),
                  "selectedDistinctBuyers",
                ),
              title: "Select a distinct buyer",
              selectedBuyers: selectedDistinctBuyers,
              footer: renderBuyerSearchFooter,
              hideBuyers: [candidate.guid],
              orgPrimaryRole,
            });
          }}
        >
          Find a buyer
        </Button>
        <span>OR</span>

        <TextButton onClick={() => openNewBuyerModal()}>Buyer doesn't exist in the list</TextButton>
      </div>
      <div className={css.listContainer}>
        <ul>
          {selectedDistinctBuyers.map((selected) => (
            <li>
              <p>
                {selected.name} {selected.is_stotles_verified && <StotlesVerifiedIcon />}
              </p>
              <TextButton
                danger
                icon={<CloseCircleOutlined />}
                onClick={() => removeDistinctBuyer(selected.guid)}
              />
            </li>
          ))}
          {newBuyers.map((newB) => (
            <li>
              <p>
                {newB.name} <NewLabel />
              </p>
              <TextButton danger onClick={() => removeNew(newB.guid)}>
                <CloseCircleOutlined />
              </TextButton>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default DistinctJourney;
