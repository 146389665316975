/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchResult } from './SearchResult';
import {
    SearchResultFromJSON,
    SearchResultFromJSONTyped,
    SearchResultToJSON,
} from './SearchResult';

/**
 * 
 * @export
 * @interface SearchEntitiesResponse
 */
export interface SearchEntitiesResponse {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof SearchEntitiesResponse
     */
    results: Array<SearchResult>;
}

/**
 * Check if a given object implements the SearchEntitiesResponse interface.
 */
export function instanceOfSearchEntitiesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function SearchEntitiesResponseFromJSON(json: any): SearchEntitiesResponse {
    return SearchEntitiesResponseFromJSONTyped(json, false);
}

export function SearchEntitiesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(SearchResultFromJSON)),
    };
}

export function SearchEntitiesResponseToJSON(value?: SearchEntitiesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(SearchResultToJSON)),
    };
}

