import React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { message } from "antd5";

import { CONTACT_REQUEST_SHEET } from "lib/featureFlags";
import { RequestOutreachContactsRequest } from "lib/generated/app-api";
import { graphql } from "lib/generated/app-service-gql";
import { useGraphQlClient } from "lib/hooks/api/useGraphQlClient";
import { useOpenApi } from "lib/openApiContext";

const buyerRequestDocument = graphql(`
  query buyers($request: BuyersRequest!) {
    buyers(BuyersRequest: $request) {
      buyers {
        id
        name
        oscarId
      }
    }
  }
`);

export function useRequestOpportunityContacts(
  options?: UseMutationOptions<void, unknown, RequestOutreachContactsRequest, unknown>,
) {
  const queryClient = useQueryClient();
  // We use a mutation here because the flow does eventually change the DB
  const api = useOpenApi();
  const isContactRequestSheetEnabled = useVariableValue(CONTACT_REQUEST_SHEET, false);
  const gqlClient = useGraphQlClient();

  const MAKE_INSTANCE_URL = "https://hook.eu1.make.com/utci7hfivhvvrt0fkhen6j7bma6clfdu";

  return useMutation(
    async (appliedFilters) => {
      await api.requestOutreachContacts({
        requestOutreachContactsRequest: appliedFilters,
      });

      if (process.env.NODE_ENV !== "development") {
        let updatedFilters;

        if (isContactRequestSheetEnabled) {
          const { buyers } = await gqlClient.client.request(buyerRequestDocument, {
            request: {
              guids: appliedFilters.contactFilters?.buyerGuids || [],
            },
          });

          updatedFilters = {
            ...appliedFilters,
            buyerNames: buyers.buyers.map((buyer) => buyer.name),
            oscarIds: buyers.buyers.map((buyer) => buyer.oscarId),
            requestedAt: new Date().toISOString(),
          };
        }

        await fetch(MAKE_INSTANCE_URL, {
          method: "POST",
          body: JSON.stringify(isContactRequestSheetEnabled ? updatedFilters : appliedFilters),
        });
      }
    },
    {
      ...options,
      onSuccess: () => {
        void queryClient.invalidateQueries(["outreachOpportunities"]);

        void message.success(
          <div>
            <div>Contact request sent! We will share relevant contacts within 5 business days.</div>
            <div>
              If you don't have access to decision maker contacts yet, we will reach out to let you
              know.
            </div>
          </div>,
        );
      },

      onError: () => {
        void message.error("Contact request unsuccessful. Please contact a member of our team.");
      },
    },
  );
}
