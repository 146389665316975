/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchFrameworkOrganisationsRequestOrganisation } from './SearchFrameworkOrganisationsRequestOrganisation';
import {
    SearchFrameworkOrganisationsRequestOrganisationFromJSON,
    SearchFrameworkOrganisationsRequestOrganisationFromJSONTyped,
    SearchFrameworkOrganisationsRequestOrganisationToJSON,
} from './SearchFrameworkOrganisationsRequestOrganisation';

/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsRequest
 */
export interface SearchFrameworkOrganisationsRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsRequest
     */
    frameworkId: string;
    /**
     * 
     * @type {SearchFrameworkOrganisationsRequestOrganisation}
     * @memberof SearchFrameworkOrganisationsRequest
     */
    organisation: SearchFrameworkOrganisationsRequestOrganisation;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsRequest interface.
 */
export function instanceOfSearchFrameworkOrganisationsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frameworkId" in value;
    isInstance = isInstance && "organisation" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsRequestFromJSON(json: any): SearchFrameworkOrganisationsRequest {
    return SearchFrameworkOrganisationsRequestFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frameworkId': json['frameworkId'],
        'organisation': SearchFrameworkOrganisationsRequestOrganisationFromJSON(json['organisation']),
    };
}

export function SearchFrameworkOrganisationsRequestToJSON(value?: SearchFrameworkOrganisationsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frameworkId': value.frameworkId,
        'organisation': SearchFrameworkOrganisationsRequestOrganisationToJSON(value.organisation),
    };
}

