/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchFrameworkOrganisationsResponseOrganisationsInner } from './SearchFrameworkOrganisationsResponseOrganisationsInner';
import {
    SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSON,
    SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSONTyped,
    SearchFrameworkOrganisationsResponseOrganisationsInnerToJSON,
} from './SearchFrameworkOrganisationsResponseOrganisationsInner';

/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsResponse
 */
export interface SearchFrameworkOrganisationsResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponse
     */
    frameworkId: string;
    /**
     * 
     * @type {Array<SearchFrameworkOrganisationsResponseOrganisationsInner>}
     * @memberof SearchFrameworkOrganisationsResponse
     */
    organisations: Array<SearchFrameworkOrganisationsResponseOrganisationsInner>;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsResponse interface.
 */
export function instanceOfSearchFrameworkOrganisationsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frameworkId" in value;
    isInstance = isInstance && "organisations" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsResponseFromJSON(json: any): SearchFrameworkOrganisationsResponse {
    return SearchFrameworkOrganisationsResponseFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frameworkId': json['frameworkId'],
        'organisations': ((json['organisations'] as Array<any>).map(SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSON)),
    };
}

export function SearchFrameworkOrganisationsResponseToJSON(value?: SearchFrameworkOrganisationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frameworkId': value.frameworkId,
        'organisations': ((value.organisations as Array<any>).map(SearchFrameworkOrganisationsResponseOrganisationsInnerToJSON)),
    };
}

