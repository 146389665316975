import * as React from "react";
import { SwapOutlined } from "@ant-design/icons";
import { Button, Divider, Popconfirm, Switch, Tag } from "antd5";

import { TextLink } from "components/actions/Links";
import TextButton from "components/actions/TextButton";
import { useDialogManager } from "lib/providers/DialogManager";
import BuyerSearchModal from "./OrgSearchModal";
import TextInputModal from "./TextInputModal";
import { HierarchicalCandidate, OrgWithStats, ValidOrgsCount } from "./types";

import css from "./HierarchicalJourney.module.scss";

type Props = {
  anchor: OrgWithStats;
  candidate: OrgWithStats;
  cleaningData: HierarchicalCandidate;
  updateValidBuyersQuestion: (v: ValidOrgsCount) => void;
  updateRelationship: (relationToAnchor: HierarchicalCandidate["relationToAnchor"]) => void;
  renameCandidate: (newName: string) => void;
  replaceCandidate: (replacedBy: OrgWithStats) => void;
  confirmHierarchy: (checked: boolean) => void;
  orgPrimaryRole: "Buyer" | "Supplier";
};

function HierarchicalJourney({
  anchor,
  candidate,
  cleaningData,
  renameCandidate,
  updateValidBuyersQuestion,
  updateRelationship,
  replaceCandidate,
  confirmHierarchy,
  orgPrimaryRole,
}: Props): JSX.Element {
  const dialogManager = useDialogManager();

  const openNewBuyerModal = React.useCallback(() => {
    void dialogManager.openDialog(TextInputModal, {
      onSubmit: (buyerName: string) => {
        renameCandidate(buyerName);
      },
      modalTitle: "Create new buyer (already exists in modal, don’t change)",
      title: "Type the buyer name that should exist ",
      description: (
        <>
          Are you sure the buyer isn’t in the existing list? Make sure to check for alternate names.
          You can do this by:
          <br />
          <ul>
            <li>
              Searching for a broader term in case of different variations (e.g., search{" "}
              <i>Camden</i> instead of <i>London borough of Camden</i>
            </li>
            <li>
              Googling the typed name (e.g., Googling “PCC for Cheshire” shows “Police and Crime
              Commissioner for Cheshire”)”
            </li>
          </ul>
        </>
      ),
    });
  }, [dialogManager, renameCandidate]);

  const renderBuyerSearchFooter = React.useCallback(
    (onClose) => {
      return (
        <div>
          <TextButton
            onClick={() => {
              openNewBuyerModal();
              onClose();
            }}
          >
            Buyer doesn't exist in list
          </TextButton>
        </div>
      );
    },
    [openNewBuyerModal],
  );

  const swapRelationship = React.useCallback(() => {
    if (cleaningData.relationToAnchor) {
      const newRelation = cleaningData.relationToAnchor === "IS_CHILD" ? "IS_PARENT" : "IS_CHILD";
      updateRelationship(newRelation);
    }
  }, [cleaningData, updateRelationship]);

  const otherValidBuyer = React.useMemo(() => {
    if (!cleaningData.candidateAction) {
      return;
    } else if (cleaningData.candidateAction.type === "RENAME") {
      return cleaningData.candidateAction.newName;
    } else {
      return cleaningData.candidateAction.replacedBy.name;
    }
  }, [cleaningData]);

  React.useEffect(() => {
    // First time a selectedBuyer is set, we auto-populate the relationship with
    // the anchor as the parent
    if (!cleaningData.relationToAnchor && otherValidBuyer) {
      updateRelationship("IS_CHILD");
    }
  }, [updateRelationship, cleaningData.relationToAnchor, otherValidBuyer]);

  const findBuyerButton = (
    <TextButton
      className={css.findBuyerButton}
      onClick={() => {
        void dialogManager.openDialog(BuyerSearchModal, {
          onSelectBuyer: replaceCandidate,
          title: "Select a distinct buyer",
          selectedBuyers:
            cleaningData?.candidateAction?.type === "MERGE"
              ? [cleaningData?.candidateAction.replacedBy]
              : [],
          footer: renderBuyerSearchFooter,
          hideBuyers: [anchor.guid, candidate.guid],
          orgPrimaryRole,
        });
      }}
    >
      {otherValidBuyer ? "Select a different buyer" : "Find a buyer"}
    </TextButton>
  );

  const parent = React.useMemo(() => {
    if (cleaningData?.relationToAnchor === "IS_PARENT") {
      return otherValidBuyer;
    } else return anchor.name;
  }, [otherValidBuyer, anchor.name, cleaningData.relationToAnchor]);

  const child = React.useMemo(() => {
    if (!otherValidBuyer) {
      return undefined;
    } else if (cleaningData.relationToAnchor === "IS_CHILD") {
      return otherValidBuyer;
    } else return anchor.name;
  }, [otherValidBuyer, anchor.name, cleaningData.relationToAnchor]);

  return (
    <div className={css.hierarchical}>
      <p className={css.instructions}>
        How many organisations in <b>{candidate.name}</b> are valid buyers?
      </p>
      <small>
        Entities are "valid buyers" if they exist in either the{" "}
        <TextLink target-type="new-tab" to="/oscar_search">
          Buyer org list
        </TextLink>{" "}
        or{" "}
        <TextLink target-type="new-tab" to="https://www.gov.uk/government/organisations">
          the gov.uk list
        </TextLink>{" "}
        list
      </small>
      <div className={css.buttonContainer}>
        <Popconfirm
          title={`Are you sure ${anchor.name} is the only valid buyer in the name?`}
          okText="Yes"
          cancelText="No"
          onConfirm={() => updateValidBuyersQuestion(ValidOrgsCount.UP_TO_ONE)}
        >
          <Button
            type={
              cleaningData.validBuyersCount === ValidOrgsCount.UP_TO_ONE ? "primary" : "default"
            }
          >
            0 or 1 valid buyers
          </Button>
        </Popconfirm>
        <Button
          onClick={() => updateValidBuyersQuestion(ValidOrgsCount.TWO)}
          type={cleaningData.validBuyersCount === ValidOrgsCount.TWO ? "primary" : "default"}
        >
          2 valid buyers
        </Button>
        <Button
          onClick={() => updateValidBuyersQuestion(ValidOrgsCount.THREE_OR_MORE)}
          type={
            cleaningData.validBuyersCount === ValidOrgsCount.THREE_OR_MORE ? "primary" : "default"
          }
        >
          3 or more valid buyers
        </Button>
      </div>
      {cleaningData.validBuyersCount === ValidOrgsCount.TWO && (
        <div>
          <Divider />
          <div className={css.relationshipsContainer}>
            <p>Define the relationship between the valid buyers</p>
            <p>
              Select the valid buyer that isn’t already shown below (ie one of the valid buyer that
              you identified in the question above above)
            </p>
            <div className={css.hierarchyVisualisation}>
              <p>
                <Tag color="blue">PARENT</Tag> {parent}{" "}
                {parent === otherValidBuyer && findBuyerButton}
              </p>

              <Button onClick={swapRelationship}>
                <SwapOutlined /> Swap parent/child
              </Button>
              <p>
                <Tag color="cyan">CHILD</Tag> {child}
                {child === otherValidBuyer && findBuyerButton}
              </p>
            </div>
          </div>
          {otherValidBuyer && (
            <div>
              <Divider />
              <p>
                Confirm that <b>{parent}</b> is the parent in relation to <b>{child}</b>. <br />
                If not, use the “Swap parent/child” button above to define the correct relationship.
              </p>
              <Switch checked={cleaningData.hierarchyConfirmed} onChange={confirmHierarchy} />
              <span> I confirm the relationship is correct</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default HierarchicalJourney;
