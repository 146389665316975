import React from "react";

import css from "./TableBanner.module.scss";

type TableBannerProps = {
  title: string;
  description: string;
  ctaElement?: JSX.Element;
};

export default function TableBanner({
  title,
  description,
  ctaElement,
}: TableBannerProps): JSX.Element {
  return (
    <div className={css.banner}>
      <div className={css.content}>
        <h4>{title}</h4>
        <span className={css.description}>{description}</span>
      </div>
      {ctaElement && ctaElement}
    </div>
  );
}
