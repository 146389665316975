/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TextMatch } from './TextMatch';
import {
    TextMatchFromJSON,
    TextMatchFromJSONTyped,
    TextMatchToJSON,
} from './TextMatch';

/**
 * 
 * @export
 * @interface RecordMatch
 */
export interface RecordMatch {
    /**
     * 
     * @type {string}
     * @memberof RecordMatch
     */
    guid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordMatch
     */
    signalIds: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<TextMatch>; }}
     * @memberof RecordMatch
     */
    textMatches: { [key: string]: Array<TextMatch>; };
    /**
     * 
     * @type {number}
     * @memberof RecordMatch
     */
    score: number;
    /**
     * 
     * @type {Array<any>}
     * @memberof RecordMatch
     */
    sort: Array<any>;
}

/**
 * Check if a given object implements the RecordMatch interface.
 */
export function instanceOfRecordMatch(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "signalIds" in value;
    isInstance = isInstance && "textMatches" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "sort" in value;

    return isInstance;
}

export function RecordMatchFromJSON(json: any): RecordMatch {
    return RecordMatchFromJSONTyped(json, false);
}

export function RecordMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordMatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'signalIds': json['signal_ids'],
        'textMatches': json['text_matches'],
        'score': json['score'],
        'sort': json['sort'],
    };
}

export function RecordMatchToJSON(value?: RecordMatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'signal_ids': value.signalIds,
        'text_matches': value.textMatches,
        'score': value.score,
        'sort': value.sort,
    };
}

