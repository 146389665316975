import * as React from "react";
import classnames from "classnames";

import css from "./Labels.module.scss";

type IconProps = {
  large?: boolean;
};

export const ProIcon = ({
  large,
  ...props
}: IconProps & React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    {...props}
    className={classnames(props.className, css.proIcon, { [css.largePlanIcon]: large })}
  >
    PRO
  </div>
);

type TextIconProps = {
  noMargin?: boolean;
  dark?: boolean;
  caps?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const BetaIcon = (props: TextIconProps): JSX.Element => (
  <div
    {...props}
    className={classnames(props.className, css.betaIcon, {
      [css.noMargin]: props.noMargin,
      [css.dark]: props.dark,
    })}
  >
    {props.caps ? "BETA" : "beta"}
  </div>
);

export function BetaTag({ className }: { className?: string }): JSX.Element {
  return <div className={classnames(css.betaTag, className)}>Beta</div>;
}

export const NewIcon = (props: TextIconProps): JSX.Element => (
  <div
    {...props}
    className={classnames(props.className, css.newIcon, {
      [css.noMargin]: props.noMargin,
      [css.dark]: props.dark,
    })}
  >
    {props.caps ? "NEW" : "new"}
  </div>
);

export const FreeIcon = ({ large }: IconProps): JSX.Element => (
  <div className={classnames(css.freeIcon, { [css.largePlanIcon]: large })}>FREE</div>
);
