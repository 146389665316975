/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StotlesEntitySearchResult } from './StotlesEntitySearchResult';
import {
    StotlesEntitySearchResultFromJSON,
    StotlesEntitySearchResultFromJSONTyped,
    StotlesEntitySearchResultToJSON,
} from './StotlesEntitySearchResult';

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    searchTerm: string;
    /**
     * 
     * @type {Array<StotlesEntitySearchResult>}
     * @memberof SearchResult
     */
    entities: Array<StotlesEntitySearchResult>;
}

/**
 * Check if a given object implements the SearchResult interface.
 */
export function instanceOfSearchResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchTerm" in value;
    isInstance = isInstance && "entities" in value;

    return isInstance;
}

export function SearchResultFromJSON(json: any): SearchResult {
    return SearchResultFromJSONTyped(json, false);
}

export function SearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerm': json['search_term'],
        'entities': ((json['entities'] as Array<any>).map(StotlesEntitySearchResultFromJSON)),
    };
}

export function SearchResultToJSON(value?: SearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_term': value.searchTerm,
        'entities': ((value.entities as Array<any>).map(StotlesEntitySearchResultToJSON)),
    };
}

