/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StotlesEntity
 */
export interface StotlesEntity {
    /**
     * 
     * @type {string}
     * @memberof StotlesEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof StotlesEntity
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof StotlesEntity
     */
    oscarId: string;
    /**
     * 
     * @type {string}
     * @memberof StotlesEntity
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof StotlesEntity
     */
    type: StotlesEntityTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof StotlesEntity
     */
    isStotlesVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StotlesEntity
     */
    isArchived: boolean;
}


/**
 * @export
 */
export const StotlesEntityTypeEnum = {
    Buyer: 'buyer',
    Supplier: 'supplier'
} as const;
export type StotlesEntityTypeEnum = typeof StotlesEntityTypeEnum[keyof typeof StotlesEntityTypeEnum];


/**
 * Check if a given object implements the StotlesEntity interface.
 */
export function instanceOfStotlesEntity(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "oscarId" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "isStotlesVerified" in value;
    isInstance = isInstance && "isArchived" in value;

    return isInstance;
}

export function StotlesEntityFromJSON(json: any): StotlesEntity {
    return StotlesEntityFromJSONTyped(json, false);
}

export function StotlesEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): StotlesEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'countryCode': json['country_code'],
        'oscarId': json['oscar_id'],
        'guid': json['guid'],
        'type': json['type'],
        'isStotlesVerified': json['is_stotles_verified'],
        'isArchived': json['is_archived'],
    };
}

export function StotlesEntityToJSON(value?: StotlesEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'country_code': value.countryCode,
        'oscar_id': value.oscarId,
        'guid': value.guid,
        'type': value.type,
        'is_stotles_verified': value.isStotlesVerified,
        'is_archived': value.isArchived,
    };
}

