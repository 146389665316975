/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount
 */
export interface SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount {
    /**
     * 
     * @type {number}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount
     */
    currency?: string;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount interface.
 */
export function instanceOfSearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSON(json: any): SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount {
    return SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
    };
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountToJSON(value?: SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'currency': value.currency,
    };
}

