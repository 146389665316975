import React from "react";
import { Modal } from "antd5";

import { FeatureType } from "components/paywall/paywallUtils";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { formatDataType } from "../../lib/utils";
import BookADemoForm from "./BookADemoForm";
import Subscription from "./Subscription";

import css from "./PaywallCTAModal.module.scss";

type PaywallCTAModalProps = {
  isOpen: boolean;
  onClose: () => void;
  showBookADemoForm: boolean;
  featureType?: FeatureType;
};

export default function PaywallCTAModal({
  isOpen,
  onClose,
  showBookADemoForm,
  featureType,
}: PaywallCTAModalProps) {
  const [showBookADemo, setShowBookADemo] = React.useState(showBookADemoForm);
  const trackingData: EventData = {
    "Context source": showBookADemoForm ? "Book a demo form" : "Compare all plans modal",
    "Data type": featureType ?? formatDataType(window.location.href) ?? "N/A",
  };
  const { logEvent } = useTracking();
  return (
    <Modal
      // the only way I could reset the form when submitting it from settings
      destroyOnClose={showBookADemo ? true : false}
      open={isOpen}
      onCancel={() => {
        if (showBookADemo) {
          logEvent(EventNames.bookADemoCancelled, {
            "Data type": formatDataType(window.location.href) ?? "N/A",
            ...trackingData,
          });
        }

        onClose();
      }}
      footer={null}
      className={showBookADemo ? css.bookADemoPaywallCTAModal : css.subscriptionPaywallCtaModal}
      centered
      classNames={{
        content: showBookADemo ? css.bookADemoModalContent : css.subscriptionModalContent,
      }}
      zIndex={10000}
    >
      {showBookADemo ? (
        <BookADemoForm trackingData={trackingData} />
      ) : (
        <Subscription
          className={css.subscriptionModalContent}
          triggerBookADemoForm={setShowBookADemo}
          trackingData={trackingData}
        />
      )}
    </Modal>
  );
}
