import React, { useEffect, useState } from "react";
import { BankOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { message } from "antd"; //upgrade message to new context
import { Button, Tooltip } from "antd5";
import debounce from "lodash.debounce";

import RequestContactsButton from "components/actions/RequestContactsButton";
import { SendContactToCRM } from "components/integrations/SendContactToCRMButton";
import { OutreachBuilderModal } from "components/outreach_builder/OutreachBuilderModal";
import PaywallPopover from "components/paywall/PaywallPopover";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import { ClipboardContent, copyToClipboard } from "lib/clipboard";
import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import RedactedWrapper, { RedactedSpan } from "lib/core_components/RedactedWrapper";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { Contact } from "lib/generated/app-service-gql/graphql";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import Sparkles from "lib/icons/Sparkles";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { white } from "lib/themes/colors";
import { EventData, EventNames, logEvent, useTracking } from "lib/tracking";
import { BuyerSummary, RecordDetails } from "lib/types/models";
import { ContactFilters } from "./contactUtils";

import css from "./ContactsTableUtils.module.scss";

import blueGeometric from "../../../assets/images/icons/stotles_geometric_square.svg";

/**
 * Common tracking function for contacts data. Only logs data which is present for a contact and
 * unifies the attributes for tracking within the context of a contact
 * @param contact
 * @param eventData
 */
function trackContactData(contact: Contact, eventName: EventNames, eventData: EventData) {
  if (contact.jobFunction) {
    eventData["Contact function"] = contact.jobFunction.join(", ");
  }

  if (contact.seniority) {
    eventData["Contact seniority"] = contact.seniority;
  }

  if (contact.buyer?.name) {
    eventData["Buyer name"] = contact.buyer.name;
  }

  if (contact.jobTitle) {
    eventData["Contact job title"] = contact.jobTitle;
  }
  logEvent(eventName, eventData);
}

/**
 * Table column which has job title, contact's name & a button which appears upon hover that allows
 * user to begin outreach
 * @param param0
 * @returns
 */
export function ContactNameCell({
  contact,
  record,
  rowIndex,
  setShowTooltipRowIndex,
  showTooltipRowIndex,
  setOutreachRef,
  setCrmRef,
  showFirstCellActions,
}: {
  contact: Contact;
  rowIndex: number;
  record?: RecordDetails;
  setShowTooltipRowIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  showTooltipRowIndex?: number;
  setOutreachRef: (outreachRef: React.MutableRefObject<null>) => void;
  setCrmRef: (crmRef: React.MutableRefObject<null>) => void;
  showFirstCellActions: boolean;
}) {
  const { checkSubscription, authorised: hasContacts } = useCheckSubscription("CONTACTS", {
    "Context source": "Find contacts",
  });

  return (
    <div className={css.contactNameCell}>
      <RedactedWrapper
        requiredDataType={"CONTACTS"}
        featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : undefined}
        contextSource="In-row"
        redactContent={
          <span onClick={checkSubscription} className={css.pointer}>
            <EllipsisTooltipText fullText={contact.jobTitle} containerClassname={css.rowHead} />
            <RedactedSpan
              textToRedact={`${contact.title} ${contact.firstName} ${contact.lastName}`}
            />
          </span>
        }
        // Just have this so that it doesn't call two modals
        showModalOnClick={false}
      >
        <div className={css.contactCellContent}>
          <EllipsisTooltipText fullText={contact.jobTitle} containerClassname={css.rowHead} />
          <EllipsisTooltipText
            fullText={`${contact.title} ${contact.firstName} ${contact.lastName}`}
            containerClassname={css.elipsisText}
          />
        </div>
      </RedactedWrapper>

      <ContactTableActionButtons
        contact={contact}
        record={record}
        rowIndex={rowIndex}
        setShowTooltipRowIndex={setShowTooltipRowIndex}
        showTooltipRowIndex={showTooltipRowIndex}
        openHelper={checkSubscription}
        hasContacts={hasContacts}
        setOutreachRef={setOutreachRef}
        setCrmRef={setCrmRef}
        showFirstCellActions={showFirstCellActions}
      />
    </div>
  );
}

/**
 * Action buttons within the contact name cell.If a user has access to contacts data and CRM integrations
 * it can allow users to create outreach and send contact to CRM. Otherwise we show a preview of the outreach modal if they don't have contacts data.
 * Also there's a tour for users who have not used outreach builder yet.
 * @param param0
 * @returns
 */
function ContactTableActionButtons({
  contact,
  record,
  rowIndex,
  openHelper,
  hasContacts,
  setShowTooltipRowIndex,
  showTooltipRowIndex,
  setOutreachRef,
  setCrmRef,
  showFirstCellActions,
}: {
  contact: Contact;
  rowIndex: number;
  record?: RecordDetails; // Also can be used to denote that we're in the related contacts page
  openHelper: () => boolean;
  hasContacts: boolean;
  setShowTooltipRowIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  showTooltipRowIndex?: number;
  //  sets refs for the related contacts tour
  setOutreachRef: (outreachRef: React.MutableRefObject<null>) => void;
  setCrmRef: (crmRef: React.MutableRefObject<null>) => void;
  showFirstCellActions: boolean;
}) {
  const outreachRef = React.useRef(null);
  const crmRef = React.useRef(null);
  const { trackingData } = useTracking();
  const contactsTrackingData = { ...trackingData, "CTA actioned": "Outreach" };
  const [isOutreachOpen, setIsOutreachOpen] = useState(false);
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(contactsTrackingData);

  useEffect(() => {
    if (rowIndex === 0 && showFirstCellActions)
      if (outreachRef?.current) {
        setOutreachRef(outreachRef);
      }

    if (crmRef?.current) {
      setCrmRef(crmRef);
    }
  }, [rowIndex, setCrmRef, setOutreachRef, showFirstCellActions]);

  const openOutreachModal = () => {
    if (window.guestUser) {
      openGuestAccessPassPaywall();
    } else if (!showFirstCellActions) {
      trackContactData(contact, EventNames.openOutreachModal, {});
      setIsOutreachOpen(true);
    }
  };

  const showFirstCell = showFirstCellActions && rowIndex === 0;

  const showContactActions = !hasContacts && rowIndex === showTooltipRowIndex;

  // Don't hide the outreach button on the first row if we need to show the outreach builder tour for users who have contacts
  return (
    <div
      className={showFirstCell || showContactActions ? undefined : SHOW_ON_HOVER}
      // This tooltip is only for users who don't have access to contacts data
      onMouseEnter={() => setShowTooltipRowIndex(rowIndex)}
    >
      <div className={css.contactActions}>
        {hasContacts ? (
          <Button
            type="primary"
            onClick={openOutreachModal}
            ref={outreachRef}
            icon={<Sparkles fill={white} className={css.icon} />}
            onMouseLeave={() => debounce(() => setShowTooltipRowIndex(undefined), 100)}
          >
            Outreach
          </Button>
        ) : (
          <PaywallPopover
            featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "CONTACTS"}
            showModalOnClick={!!window.guestUser}
            contextSource={"In-row"}
            trackingData={contactsTrackingData}
          >
            <Button
              type="primary"
              onClick={() => {
                if (!showFirstCellActions && !window.guestUser) openHelper();
              }}
              className={css.outreachButtonDisabled}
              icon={<Sparkles fill={white} className={css.icon} />}
              ref={outreachRef}
            >
              Outreach
            </Button>
          </PaywallPopover>
        )}
        <span ref={crmRef}>
          <SendContactToCRM contact={contact} showingTour={showFirstCellActions} />
        </span>
      </div>
      <OutreachBuilderModal
        isOpen={isOutreachOpen}
        contactEmail={contact.email || ""}
        contactName={`${contact.firstName} ${contact.lastName}`}
        jobTitle={contact.jobTitle}
        buyerName={contact.buyer?.name || ""}
        record={record}
        onClose={() => setIsOutreachOpen(false)}
      />
    </div>
  );
}

/**
 * Table column which has buyer name + buyer category. Code basically copied from buyer list table
 * @param param0
 * @returns
 */
export function BuyerDetailsCell({ contact, buyer }: { contact: Contact; buyer?: BuyerSummary }) {
  return (
    <div className={css.buyerNameCell}>
      <div className={css.buyerLogoContainer}>
        <BuyerLogo url={buyer ? buyer.metadata?.logo_url : undefined} />
      </div>
      <div>
        {buyer ? (
          <>
            <EllipsisTooltipTextLink
              fullText={buyer.name}
              linkText={buyer.name}
              linkProps={{
                to: `/buyers/${buyer.id}`,
                className: css.marginBottom,
              }}
              containerClassname={css.rowHead}
            />

            <BuyerCategoriesWithPopover
              size="default"
              buyerCategories={buyer.categories.map((c) => ({
                buyer_category_id: c.buyer_category_id,
                source: c.source,
              }))}
              buyerName={buyer.name}
            />
          </>
        ) : (
          <Tooltip
            placement="top"
            title="This buyer is currently not linked to any in our system"
            trigger="click"
          >
            <EllipsisTooltipText
              fullText={contact.buyer?.name ?? `--`}
              containerClassname={css.rowHead}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function BuyerLogo({ url }: { url?: string | null }) {
  return url ? (
    <img src={url} height={24} width={24} className={css.buyerIcon} />
  ) : (
    <BankOutlined className={css.placeholderBuyerIcon} />
  );
}

/**
 * Table column which has contact email + contact phone. Allows users with contacts data access to see the table rows &
 * copy their contents. Those without access have this information blurred like elsewhere in the app
 * @param param0
 * @returns
 */
export function ContactInformationCell({
  contact,
  onCopy,
}: {
  contact: Contact;
  onCopy: () => void;
}) {
  const handleCopyToClipboard = (eventName: EventNames, copyContent?: string) => {
    if (copyContent) {
      const eventData: EventData = {
        "Copied value": copyContent,
      };

      trackContactData(contact, eventName, eventData);

      const content: ClipboardContent = { plain: copyContent };
      copyToClipboard(content);

      // If this is coming from the perspective of a record, we automatically update the record's qualification
      // status to Pre-engage: Actioned, also updates a contact to be Actioned
      onCopy();

      void message.success("Copied!", 2);
    }
  };

  return (
    <div className={css.contactCellContent}>
      {contact.email ? (
        <Tooltip placement="topLeft" title="Copy email to clipboard">
          <span
            className={css.contactDetail}
            onClick={() => handleCopyToClipboard(EventNames.emailCopied, contact.email || "")}
          >
            <span className={css.contactDetailContent}>
              <MailOutlined className={css.contactIcon} /> {contact.email}
            </span>
          </span>
        </Tooltip>
      ) : (
        <span className={css.contactDetail}>
          <MailOutlined className={css.contactIcon} /> <span className={css.emptyState}>--</span>
        </span>
      )}

      {contact.phone ? (
        <Tooltip placement="topLeft" title="Copy contact number to clipboard">
          <span
            className={css.contactDetail}
            onClick={() => handleCopyToClipboard(EventNames.phoneNumberCopied, contact.phone || "")}
          >
            <span className={css.contactDetailContent}>
              <PhoneOutlined className={css.contactIcon} />
              {contact.phone}
            </span>
          </span>
        </Tooltip>
      ) : (
        <span className={css.contactDetail}>
          <PhoneOutlined className={css.contactIcon} /> <span className={css.emptyState}>--</span>
        </span>
      )}
    </div>
  );
}

type Props = {
  contactFilters?: ContactFilters;
  notice?: RecordDetails;
  title?: string;
};
export function ContactsTableEmpty({ contactFilters, notice, title }: Props): JSX.Element {
  return (
    <div className={css.emptyStateScreen}>
      <img className={css.graphic} src={blueGeometric} />
      <h2 className={css.headerText}>No Contacts found</h2>
      {!window.guestUser && (
        <>
          <p className={css.normalText}>
            A member of our team is always happy to take a look and see if they can find contacts
            from other sources. Just send a request below and we will be in touch soon.
          </p>
          <RequestContactsButton
            title={title}
            contactFilters={contactFilters}
            notice={notice ? { guid: notice.guid, name: notice.name } : undefined}
            contextSource="No contacts found"
          />
        </>
      )}
    </div>
  );
}
