import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { App, Button, Input, Modal } from "antd5";

import {
  CATEGORY_TYPES,
  ContactFilters,
  SENIORITY_OPTIONS,
} from "components/contacts/contactUtils";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { Select, TextArea } from "components/form_components/Inputs";
import { useRequestOpportunityContacts } from "lib/hooks/api/contacts/useRequestOpportunityContacts";
import { EventData, EventNames, logEvent } from "lib/tracking";

import css from "./RequestContactsModal.module.scss";

import CONTACT_BOOK from "../../../assets/images/contact_book.svg";

type RequestContactsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  contactFilters?: ContactFilters;
  notice?: {
    guid: string;
    name: string;
    buyer?: { guid: string; name: string };
  };
};

type ContactRequestFormFields = {
  seniority?: string[];
  function?: string[];
  other?: string;
  selectedBuyerIds?: string[];
};

export default function RequestContactsModal({
  isOpen,
  onClose,
  contactFilters,
  notice,
}: RequestContactsModalProps) {
  const { message } = App.useApp();

  const defaultValues = {
    seniority: contactFilters?.seniority,
    function: contactFilters?.jobFunction,
    other: "",
    selectedBuyerIds: [],
  };

  const { control, handleSubmit, reset } = useForm<ContactRequestFormFields>({
    mode: "onSubmit",
    defaultValues: defaultValues,
  });

  const { mutate: sendRequestContactsRequest } = useRequestOpportunityContacts();

  const buyerSelectionRequired =
    (!notice || !notice.buyer) && contactFilters?.buyerGuids?.length === 0;

  const requestContacts = (formInput: ContactRequestFormFields) => {
    const eventData: EventData = {};
    // Tracking attributes + request different depending on context of requesting contacts
    if (notice) {
      eventData["Notice title"] = notice.name;
      eventData["Buyer"] = notice?.buyer?.name;

      sendRequestContactsRequest({
        noticeFilters: {
          noticeGuid: notice.guid,
          noticeTitle: notice.name,
          buyerGuid: notice?.buyer?.guid || "",
          buyerName: notice?.buyer?.name || "",
        },
        additionalInformation: formInput.other,
      });
    } else if (contactFilters) {
      const combinedFormAndFilters = {
        ...contactFilters,
        jobFunction: formInput.function || contactFilters.jobFunction,
        seniority: formInput.seniority || contactFilters.seniority,
        buyerIds:
          contactFilters.buyerGuids.length > 0
            ? contactFilters.buyerGuids
            : formInput.selectedBuyerIds || [],
      };

      eventData["Buyer IDs"] = combinedFormAndFilters.buyerGuids;

      if (combinedFormAndFilters.jobFunction) {
        eventData["Contact function"] = combinedFormAndFilters.jobFunction.join(", ");
      }

      if (combinedFormAndFilters.seniority) {
        eventData["Contact Seniority"] = combinedFormAndFilters.seniority;
      }

      if (combinedFormAndFilters.jobTitle) {
        eventData["Job title search"] = combinedFormAndFilters.jobTitle;
      }

      sendRequestContactsRequest({
        contactFilters: {
          ...combinedFormAndFilters,
        },
        additionalInformation: formInput.other,
      });
    } else {
      void message.error("Contact request unsuccessful. Please contact a member of our team.");
    }

    logEvent(EventNames.contactDetailsRequested, eventData);
  };

  const handleOnClose = () => {
    onClose();
    reset(defaultValues);
  };

  useEffect(() => {
    reset({
      seniority: contactFilters?.seniority,
      function: contactFilters?.jobFunction,
      selectedBuyerIds: [],
      other: "",
    });
  }, [reset, contactFilters]);

  return (
    <Modal open={isOpen} onCancel={() => handleOnClose()} footer={null}>
      <img src={CONTACT_BOOK} className={css.contactBookImage} />
      <h2 className={css.modalHeader}>
        {buyerSelectionRequired ? "Request contacts" : "Request contacts for this buyer"}
      </h2>
      <p className={css.description}>
        Tell us more about the type of contacts you're interested in and your customer success
        manager will get back to you in 2 working days.
      </p>
      <form
        onSubmit={handleSubmit((formInput) => {
          requestContacts(formInput);
          handleOnClose();
        })}
        className={css.formModal}
      >
        <div className={css.formField}>
          <Select
            mode="multiple"
            name={"seniority"}
            label={"Seniority"}
            options={SENIORITY_OPTIONS.map((sen) => ({
              title: sen.title,
              value: sen.value,
              label: sen.title,
            }))}
            control={control}
            rules={{ required: { value: true, message: "Seniority is required" } }}
            placeholder="Select"
          />
        </div>

        <div className={css.formField}>
          <Select
            mode="multiple"
            name={"function"}
            label={"Function"}
            options={CATEGORY_TYPES.map((cat) => ({
              title: cat.title,
              value: cat.value,
              label: cat.title,
            }))}
            control={control}
            rules={{ required: { value: true, message: "Function is required" } }}
            placeholder="Select"
          />
        </div>

        {/* When the request contacts form is on a general page, not related to a buyer/notice */}
        {buyerSelectionRequired && (
          <div className={css.formField}>
            <BuyerSelect
              name="selectedBuyerIds"
              label="Buyers"
              control={control}
              mode="multiple"
              placeholder="Search buyers"
              rules={{ required: { value: true, message: "Buyer is required" } }}
            />
          </div>
        )}

        <TextArea
          name={"other"}
          label={"Other (optional)"}
          control={control}
          placeholder="Share any information that might be useful for your customer success manager i.e. job titles, preferred contact information, keywords."
        />
        <p className={css.subDescription}>
          While it's not guaranteed we'll be able to find all the contact information you need,
          filling in this form will allow your customer success manager to be more targeted in their
          search.
        </p>
        <div className={css.footer}>
          <Button onClick={() => handleOnClose()}>Cancel</Button>
          <Input className={css.okButton} type="submit" value="Request Contacts" />
        </div>
      </form>
    </Modal>
  );
}
