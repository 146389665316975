/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Metadata
 */
export interface Metadata {
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    stotlesScore: number;
    /**
     * 
     * @type {number}
     * @memberof Metadata
     */
    vectorSimilarity?: number;
    /**
     * 
     * @type {object}
     * @memberof Metadata
     */
    scoreExplanation: object;
}

/**
 * Check if a given object implements the Metadata interface.
 */
export function instanceOfMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "stotlesScore" in value;
    isInstance = isInstance && "scoreExplanation" in value;

    return isInstance;
}

export function MetadataFromJSON(json: any): Metadata {
    return MetadataFromJSONTyped(json, false);
}

export function MetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'stotlesScore': json['stotles_score'],
        'vectorSimilarity': !exists(json, 'vector_similarity') ? undefined : json['vector_similarity'],
        'scoreExplanation': json['score_explanation'],
    };
}

export function MetadataToJSON(value?: Metadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'stotles_score': value.stotlesScore,
        'vector_similarity': value.vectorSimilarity,
        'score_explanation': value.scoreExplanation,
    };
}

