import React from "react";

import { EventNames, useTracking } from "../../../lib/tracking";
import { camelToSentence } from "../../../lib/utils";
import CollapseGroup, { CollapseItem } from "../../ui/collapse_group/CollapseGroup";

type FilterCollapseProps = {
  items: CollapseItem[];
  value: string[];
  onChange: (value: string[]) => void;
};

function FilterCollapse({ items, value, onChange }: FilterCollapseProps) {
  const { logEvent } = useTracking();

  function onChangeWithEvents(groups: string[]) {
    const closedGroups = value.filter((group) => !groups.includes(group));
    const openedGroups = groups.filter((group) => !value.includes(group));

    closedGroups?.forEach((group) => {
      logEvent(EventNames.filterCollapseActioned, {
        "Filter label": camelToSentence(group),
        "Action type": "closed",
      });
    });

    openedGroups.forEach((group) => {
      logEvent(EventNames.filterCollapseActioned, {
        "Filter label": camelToSentence(group),
        "Action type": "opened",
      });
    });

    onChange(groups);
  }

  return <CollapseGroup items={items} value={value} onChange={onChangeWithEvents} />;
}

export default FilterCollapse;
