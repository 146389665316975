/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Metadata } from './Metadata';
import {
    MetadataFromJSON,
    MetadataFromJSONTyped,
    MetadataToJSON,
} from './Metadata';
import type { StotlesEntity } from './StotlesEntity';
import {
    StotlesEntityFromJSON,
    StotlesEntityFromJSONTyped,
    StotlesEntityToJSON,
} from './StotlesEntity';

/**
 * 
 * @export
 * @interface StotlesEntitySearchResult
 */
export interface StotlesEntitySearchResult {
    /**
     * 
     * @type {StotlesEntity}
     * @memberof StotlesEntitySearchResult
     */
    entity: StotlesEntity;
    /**
     * 
     * @type {Metadata}
     * @memberof StotlesEntitySearchResult
     */
    metadata: Metadata;
}

/**
 * Check if a given object implements the StotlesEntitySearchResult interface.
 */
export function instanceOfStotlesEntitySearchResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function StotlesEntitySearchResultFromJSON(json: any): StotlesEntitySearchResult {
    return StotlesEntitySearchResultFromJSONTyped(json, false);
}

export function StotlesEntitySearchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): StotlesEntitySearchResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': StotlesEntityFromJSON(json['entity']),
        'metadata': MetadataFromJSON(json['metadata']),
    };
}

export function StotlesEntitySearchResultToJSON(value?: StotlesEntitySearchResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': StotlesEntityToJSON(value.entity),
        'metadata': MetadataToJSON(value.metadata),
    };
}

