import * as React from "react";

import { TextLink } from "components/actions/Links";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import SignalsContainer from "components/tags/SignalsContainer";
import RecordStage from "lib/core_components/RecordStage";
import { ReportRecord } from "lib/generated/admin-api";
import { EventNames } from "lib/tracking";
import { LeadSignal, TenderStage } from "lib/types/models";
import { formatAmount } from "lib/utils";
import { formatReportDate } from "../../utils";

import css from "./RecordList.module.scss";

import BANK from "../../../../../assets/images/icons/bank.svg";
import TIME_ICON from "../../../../../assets/images/icons/field_time.svg";
import TAG_ICON from "../../../../../assets/images/icons/tag.svg";

type RecordListProps = {
  title?: string;
  records: ReportRecord[];
  description?: string;
  id: string;
};

function RecordList({ records, title, description, id }: RecordListProps): JSX.Element {
  return (
    <div className={css.recordListSection} id={id}>
      <SectionHeader title={title} description={description} />
      <ul className={css.recordList}>
        {records.map((r, i) => (
          <>
            {i !== 0 && <hr />}
            <li key={r.guid} className={css.recordListItem}>
              <div className={css.itemHeader}>
                <RecordStage className={css.stage} stage={TenderStage[r.stage]} />
                {r.leadSignals.length > 0 && (
                  <SignalsContainer signals={r.leadSignals as LeadSignal[]} />
                )}
                <p className={css.publishDate}>Published {formatReportDate(r.publishDate)}</p>
              </div>
              <h3 className={css.itemTitle}>
                <TextLink
                  to={r.canonicalUrlPath}
                  targetType="new-tab"
                  eventName={EventNames.reportRecordListItemClicked}
                  eventAttributes={{ link: r.canonicalUrlPath }}
                >
                  {r.name}
                </TextLink>
              </h3>
              <div className={css.detailsWrapper}>
                {r.buyer && (
                  <span className={css.recordDetail}>
                    <img height={14} width={14} src={BANK} alt="bank-icon" />
                    {r.buyer.name}
                  </span>
                )}
                {r.valueSrc && (
                  <span className={css.recordDetail}>
                    <img height={14} width={14} src={TAG_ICON} alt="price-tag-icon" />

                    {formatAmount(r.valueSrc, r.currency)}
                  </span>
                )}
                {r.closeDate && (
                  <span className={css.recordDetail}>
                    <img height={14} width={14} src={TIME_ICON} alt="time-icon" />
                    Close date: {formatReportDate(r.closeDate)}
                  </span>
                )}
                {r.expiryDate && (
                  <span className={css.recordDetail}>
                    <img height={14} width={14} src={TIME_ICON} alt="time-icon" />
                    Expiry date: {formatReportDate(r.expiryDate)}
                  </span>
                )}
              </div>
              {r.description && <p className={css.recordDescription}>{r.description.trim()}</p>}
            </li>
          </>
        ))}
      </ul>
    </div>
  );
}

export default RecordList;
