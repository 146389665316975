import * as React from "react";

export function createHandleTableRowClickthrough<Item>(
  createDestination: (item: Item) => string,
  otherListeners: Partial<React.HTMLAttributes<HTMLElement>> | undefined = undefined,
  onClick: ((item: Item) => void) | undefined = undefined,
  restrictedAccessCheck: ((item: Item) => boolean) | undefined = undefined,
  alternativeOnClick: (() => React.HTMLAttributes<HTMLElement>) | undefined = undefined,
) {
  return function (
    item: Item,
    _itemRowIndex: number | undefined,
  ): React.HTMLAttributes<HTMLElement> {
    // If restrictedAccessCheck and alternativeOnClick are defined, then we'll first
    //check the condition and then either return an empty TableEventListeners or fire off the alternativeOnClick
    if (restrictedAccessCheck && restrictedAccessCheck(item)) {
      if (alternativeOnClick) {
        return alternativeOnClick();
      } else return {} as React.HTMLAttributes<HTMLElement>;
    }

    const handleClickEventAsLink = (e: React.MouseEvent) => {
      if (onClick) {
        onClick(item);
      }

      // metaKey: ctrl/cmd ➡ new tab  \
      // shiftKey: shift ➡ new window  |-- _blank (typically new tab, but browser/user prefs dependent)
      // middle click: ➡ new tab      /

      // normal click: ➡ same tab  ]- _self
      const newTab = e.ctrlKey || e.metaKey || e.shiftKey || e.button === 1;
      const destination = createDestination(item);
      window.open(destination, newTab ? "_blank" : "_self");

      e.stopPropagation();
    };

    const handleAuxClickEventAsLink = (e: React.MouseEvent) => {
      if (e.button === 1) {
        handleClickEventAsLink(e);
      }
    };

    return {
      ...otherListeners,
      onClick: handleClickEventAsLink,
      onAuxClick: handleAuxClickEventAsLink,
    };
  };
}
