import React from "react";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

import Badge from "../../ui/badge/badge";

import css from "./FilterCollapseHeader.module.scss";

export interface FilterCollapseHeaderProps {
  title: string;
  count?: number;
  clear: () => void;
  tag?: (title: string) => React.ReactNode;
}

function FilterCollapseHeader({ title, count, clear, tag }: FilterCollapseHeaderProps) {
  return (
    <div className={css.wrapper}>
      {
        <div className={css.titleContainer}>
          {title}
          {tag && tag(title)}
        </div>
      }
      {count
        ? count > 0 && (
            <Badge
              iconOnClick={clear}
              icon={
                <span className={css.closeIconWrapper}>
                  <CloseCircleOutlined className={css.closeIconDefault} />
                  <CloseCircleFilled className={css.closeIconHover} />
                </span>
              }
            >
              {count}
            </Badge>
          )
        : null}
    </div>
  );
}

export default FilterCollapseHeader;
