import React, { useMemo, useState } from "react";
import { SearchOutlined, WarningOutlined } from "@ant-design/icons";
import { Empty, Input } from "antd5";

import { TextLink } from "components/actions/Links";
import { stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { createUseDebounce } from "lib/debounce";
import {
  SearchOrganisationsRequest,
  SearchOrganisationsRequest_PrimaryRoleEnum_0 as OrgPrimaryRole,
  SearchOrganisationsRequest_SortOrderEnum_0 as SortOrder,
} from "lib/generated/app-service-gql/graphql";
import {
  OrgBasicInfo,
  useSearchOrganisations,
} from "lib/hooks/api/organisations/useSearchOrganisations";
import { ExternalLink } from "lib/icons/ExternalLink";
import { deduplicateByKey } from "lib/utils";

import css from "./OrganisationSearchTable.module.scss";

const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };
function getSortOrder(key: string, sort?: SortOrder): "ascend" | "descend" | null {
  return sort !== key ? null : sort === "ASC" ? "ascend" : "descend";
}

function getOrganisationColumns(filters: SearchOrganisationsRequest): ColumnType<OrgBasicInfo>[] {
  const name: ColumnType<OrgBasicInfo> = {
    title: "Organisation",
    dataIndex: "name",
    key: "name",
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: stringSort((s) => s.name),
    sortOrder: getSortOrder("name", filters.sortOrder),
    render: (_, s) => (
      <EllipsisTooltipTextLink
        fullText={s.name}
        linkText={<b>{s.name}</b>}
        linkProps={{ to: `/suppliers/${s.id}` }}
        containerClassname={css.orgName}
      />
    ),
    sizeConfig: {
      small: 250,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const country: ColumnType<OrgBasicInfo> = {
    title: "Country",
    dataIndex: "countryCode",
    key: "countryCode",
    sizeConfig: {
      small: 100,
      medium: 100,
      large: 125,
      xlarge: 250,
    },
  };

  const url: ColumnType<OrgBasicInfo> = {
    title: "Website URL",
    dataIndex: "url",
    key: "url",
    render: (_, s) =>
      s.url ? (
        <TextLink to={s.url} targetType="new-tab" className={css.url}>
          <ExternalLink className={css.link} />
          {s.url}
        </TextLink>
      ) : (
        ""
      ),
    sizeConfig: {
      small: 100,
      medium: 200,
      large: 225,
      xlarge: 250,
    },
  };

  // If we want to add more columns, we can add them here. Could be a generic org table in future

  return [name, url, country];
}

type Props = {
  selectedOrgs: OrgBasicInfo[];
  onOrgsChange: (selectedOrgs: OrgBasicInfo[]) => void;
  primaryRole?: OrgPrimaryRole;
  containerClassname?: string;
};

function OrganisationSearchTable({
  selectedOrgs,
  onOrgsChange,
  primaryRole,
  containerClassname,
}: Props): JSX.Element {
  const [textSearch, setTextSearch] = useState("");

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [filters, setFilters] = useState<SearchOrganisationsRequest>({
    textSearch,
    primaryRole,
    limit: pagination.pageSize,
    page: pagination.current,
    sortOrder: SortOrder.Asc,
  });

  const { isLoading, data, isError } = useSearchOrganisations({
    ...filters,
    page: pagination.current,
    limit: pagination.pageSize,
  });
  const useDebounce = createUseDebounce(500);

  const debouncedSetFilters = useDebounce((f: SearchOrganisationsRequest) => {
    setFilters(f);
    setTextSearch(f.textSearch);
  });

  const columns = useMemo(() => getOrganisationColumns(filters), [filters]);

  return (
    <div className={containerClassname}>
      <h3>Select Organisations</h3>
      <Input
        placeholder="Search by organisation name"
        prefix={<SearchOutlined />}
        value={textSearch}
        onChange={(e) => {
          setTextSearch(e.target.value);
          debouncedSetFilters({ ...filters, textSearch: e.target.value });
        }}
        allowClear
        className={css.searchInput}
      />
      <Table<OrgBasicInfo>
        dataSource={data?.searchOrganisations.orgs || []}
        columns={columns}
        loading={isLoading}
        pagination={{
          ...pagination,
          total: data?.searchOrganisations.total,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize });
          },
          showSizeChanger: true,
        }}
        onChange={(_, __, sorter) => {
          if (Array.isArray(sorter)) {
            sorter = sorter[0];
          }
          // If the sort order is not defined, we don't want to trigger a sort without an order
          if (!filters.sortOrder && !sorter.order) {
            return;
          }
          debouncedSetFilters({
            ...filters,
            sortOrder: sorter.order === "descend" ? SortOrder.Desc : SortOrder.Asc,
          });
        }}
        rowSelection={{
          onChange: (selectedOrgIds) => {
            const newSelectedOrgs =
              data?.searchOrganisations.orgs.filter((org) => selectedOrgIds.includes(org.id)) ?? [];
            const retainedOrgs = selectedOrgs.filter((o) => selectedOrgIds.includes(o.id));

            const allOrgs = Array.from(
              deduplicateByKey([...newSelectedOrgs, ...retainedOrgs], (org: OrgBasicInfo) => {
                return org.id;
              }),
            );
            onOrgsChange(allOrgs);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedOrgs.map((s) => s.id),
        }}
        rowKey="id"
        locale={{
          emptyText: isError ? (
            <Empty
              image={<WarningOutlined style={{ fontSize: 100 }} />}
              description="Sorry something has gone wrong"
            />
          ) : undefined,
        }}
      />
    </div>
  );
}

export default OrganisationSearchTable;
