import React from "react";
import { Link } from "wouter";

import TextButton from "components/actions/TextButton";
import { IN_ROW_STAT_LINK, InRowStatText } from "lib/core_components/Table/Table";
import { EventData, EventNames, useTracking } from "lib/tracking";

import css from "./LinkCell.module.scss";

export function LinkCell<T>({
  value,
  destination,
  tracking,
  authorised,
  onUnauthorisedClick,
}: {
  value: T;
  destination: string;
  tracking?: { eventName: EventNames; data?: EventData };
  onUnauthorisedClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  authorised?: boolean;
}) {
  const { logEvent } = useTracking();
  if (typeof value === "number" && value === 0) {
    return <InRowStatText>{value}</InRowStatText>;
  }

  if (!authorised) {
    return (
      <TextButton className={css.textLink} onClick={onUnauthorisedClick}>
        {value}
      </TextButton>
    );
  }

  return (
    // surrounding div to sop propagation of click events from table row
    <div
      onClick={(e) => {
        e.stopPropagation();
        if (tracking) {
          logEvent(tracking.eventName, tracking.data);
        }
      }}
    >
      <Link className={IN_ROW_STAT_LINK} href={destination}>
        {value}
      </Link>
    </div>
  );
}
