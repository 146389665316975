import React from "react";
import { Control, Controller } from "react-hook-form";
import { Alert, Tag } from "antd5";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { SearchOrganisationsRequest_PrimaryRoleEnum_0 as OrgPrimaryRole } from "lib/generated/app-service-gql/graphql";
import AliasSelection from "./AliasSelection";
import OrganisationSearchTable from "./OrganisationSearchTable";
import SignalsToMigrateSelection from "./SignalsToMigrateSelection";

import css from "./MigrateSignalsStep.module.scss";

type Props = {
  control: Control<MigrateSignalsFormState, unknown>;
};

function MigrateSignalsStep({ control }: Props): JSX.Element {
  return (
    <>
      <div className={css.pageContent}>
        <Controller
          name="signals"
          control={control}
          rules={{
            required: "Please select at least one signal",
          }}
          render={({ field: { value: signals, onChange }, fieldState: { error, isDirty } }) => (
            <div className={css.section}>
              {error && isDirty && (
                <Alert message={error.message} type="error" showIcon className={css.alert} />
              )}
              <SignalsToMigrateSelection selectedSignals={signals} onSignalsChange={onChange} />
              <div className={css.tagsContainer}>
                {signals.map((signal) => (
                  <ul>
                    <li key={signal.name}>
                      <Tag
                        key={signal.name}
                        closable={true}
                        color="green"
                        className={css.tag}
                        onClose={() => onChange(signals.filter((s) => s.name !== signal.name))}
                      >
                        <EllipsisTooltipText
                          fullText={`${signal.name} (${signal.ids.length})`}
                          containerClassname={css.selectedItem}
                        />
                      </Tag>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          )}
        />
        <Controller
          name="orgs"
          control={control}
          rules={{
            required: "Please select at least one organisation",
          }}
          render={({ field: { value: orgs, onChange }, fieldState: { error, isDirty } }) => (
            <div className={css.section}>
              {error && isDirty && (
                <Alert message={error.message} type="error" showIcon className={css.alert} />
              )}
              <OrganisationSearchTable
                selectedOrgs={orgs}
                onOrgsChange={onChange}
                primaryRole={OrgPrimaryRole.Supplier}
                containerClassname={css.tableContainer}
              />
              <div className={css.tagsContainer}>
                {orgs.map((org) => (
                  <ul>
                    <li key={org.id}>
                      <Tag
                        key={org.id}
                        closable={true}
                        color="geekblue"
                        className={css.tag}
                        onClose={() => onChange(orgs.filter((o) => o.id !== org.id))}
                      >
                        <EllipsisTooltipTextLink
                          fullText={org.name}
                          linkText={<b>{org.name}</b>}
                          linkProps={{ to: `/suppliers/${org.id}` }}
                          containerClassname={css.selectedItem}
                        />
                      </Tag>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          )}
        />
        <Controller
          name="aliases"
          control={control}
          render={({ field: { value: aliases, onChange } }) => (
            <div className={css.section}>
              <AliasSelection selectedAliases={aliases} onAliasChange={onChange} />
              <div className={css.tagsContainer}>
                {aliases.map((alias) => (
                  <ul>
                    <li key={alias}>
                      <Tag
                        key={alias}
                        closable={true}
                        color="orange"
                        className={css.tag}
                        onClose={() => onChange(aliases.filter((a) => a !== alias))}
                      >
                        <EllipsisTooltipText
                          fullText={alias}
                          containerClassname={css.selectedItem}
                        />
                      </Tag>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          )}
        />
      </div>
    </>
  );
}

export default MigrateSignalsStep;
