import * as React from "react";
import { Button } from "antd5";

import PaywallCTAModal from "components/account_management/PaywallCTAModal";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import { ExpertPlan, GrowthPlan } from "lib/icons/PlanIcon";
import { useDialogManager } from "lib/providers/DialogManager";
import { EventData } from "lib/tracking";
import { FeatureType, getPaywallContent, PaidTag } from "./paywallUtils";

import css from "./PaywallContent.module.scss";

type Props = {
  featureType: FeatureType;
  isAddOn?: boolean;
  trackingData?: EventData;
};

/**
 * This component is reused in the PaywallBanner and PaywallModal components. It has a description
 * for each paywall type, as well as the accompanying buttons which link to the upgrade pages.
 * @param param0
 * @returns
 */
export function PaywallContent({ featureType, isAddOn, trackingData }: Props): JSX.Element {
  const { title, description, bullets, plantype } = getPaywallContent(featureType);

  const isAddOnFeature = isAddOn || featureType === "CONTACTS";

  return (
    <div className={css.upgradeContainer}>
      <div className={css.upgradeContent}>
        <PaidTag />
        <h1 className={css.title}>{title}</h1>
        <p className={css.txt}>{description}</p>
        {bullets?.length && (
          <ul className={css.bullets}>
            {bullets?.map((content, index) => <li key={index}>{content}</li>)}
          </ul>
        )}
        {plantype && (
          <span className={css.planText}>
            {plantype === "Growth" ? (
              <GrowthPlan className={css.planIcon} />
            ) : (
              <ExpertPlan className={css.planIcon} />
            )}
            This feature is available {isAddOnFeature ? "as an add on to" : "on"} our {plantype}{" "}
            plan
          </span>
        )}
        <PaywallButtons featureType={featureType} trackingData={trackingData} />
      </div>
    </div>
  );
}

type PaywallButtonProps = {
  featureType?: FeatureType;
  trackingData?: EventData;
};
export function PaywallButtons({ featureType, trackingData }: PaywallButtonProps) {
  const { mutate } = usePqlActioned(undefined, trackingData);
  const dialogManager = useDialogManager();

  return (
    <div className={css.btnContainer}>
      {
        <>
          <Button
            type="primary"
            className={css.btn}
            onClick={() => {
              mutate({
                actionType: "Book a demo in-app",
                dataType: featureType ?? "",
                pageUrl: window.location.href,
              });

              void dialogManager.openDialog(PaywallCTAModal, {
                showBookADemoForm: true,
                featureType: featureType,
              });
            }}
          >
            Book a demo
          </Button>
          <Button
            className={css.btn}
            onClick={() => {
              mutate({
                actionType: "Compare all plans",
                dataType: featureType ?? "",
                pageUrl: window.location.href,
              });

              void dialogManager.openDialog(PaywallCTAModal, {
                showBookADemoForm: false,
                featureType: featureType,
              });
            }}
          >
            Compare all plans
          </Button>
        </>
      }
    </div>
  );
}
