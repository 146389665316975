/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PqlActionedRequest
 */
export interface PqlActionedRequest {
    /**
     * 
     * @type {string}
     * @memberof PqlActionedRequest
     */
    actionType: PqlActionedRequestActionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PqlActionedRequest
     */
    dataType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PqlActionedRequest
     */
    pageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PqlActionedRequest
     */
    contextSource?: string | null;
}


/**
 * @export
 */
export const PqlActionedRequestActionTypeEnum = {
    BookADemo: 'Book a demo',
    SpeakToSales: 'Speak to sales',
    CompareAllPlans: 'Compare all plans',
    Upgrade: 'Upgrade',
    BookADemoInApp: 'Book a demo in-app',
    BookADemoInAppFormSubmission: 'Book a demo in-app form submission',
    SpeakToYourCsm: 'Speak to your CSM'
} as const;
export type PqlActionedRequestActionTypeEnum = typeof PqlActionedRequestActionTypeEnum[keyof typeof PqlActionedRequestActionTypeEnum];


/**
 * Check if a given object implements the PqlActionedRequest interface.
 */
export function instanceOfPqlActionedRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actionType" in value;

    return isInstance;
}

export function PqlActionedRequestFromJSON(json: any): PqlActionedRequest {
    return PqlActionedRequestFromJSONTyped(json, false);
}

export function PqlActionedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PqlActionedRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionType': json['action_type'],
        'dataType': !exists(json, 'data_type') ? undefined : json['data_type'],
        'pageUrl': !exists(json, 'page_url') ? undefined : json['page_url'],
        'contextSource': !exists(json, 'context_source') ? undefined : json['context_source'],
    };
}

export function PqlActionedRequestToJSON(value?: PqlActionedRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action_type': value.actionType,
        'data_type': value.dataType,
        'page_url': value.pageUrl,
        'context_source': value.contextSource,
    };
}

