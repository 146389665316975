import React from "react";
import { Control } from "react-hook-form";

import { Input, Select } from "components/form_components/Inputs";
import { SENIORITY_OPTIONS } from "../contactUtils";
import { ContactFiltersComplete } from "../FilterForm";
import { JOB_FUNCTION_OPTIONS } from "../utils";

import css from "./SharedFilterGroups.module.scss";

export interface JobAndSeniorityFilterContentProps {
  control: Control<ContactFiltersComplete, unknown>;
}

function JobAndSeniorityFilterContent({ control }: JobAndSeniorityFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Contacts filters">
      <Input name="jobTitle" label="Job title" control={control} placeholder="Search job titles" />
      <Select
        name="seniority"
        label="Seniority"
        mode="multiple"
        control={control}
        options={SENIORITY_OPTIONS}
        placeholder="Select"
      />
      <Select
        name="jobFunction"
        label="Function"
        mode="multiple"
        control={control}
        options={JOB_FUNCTION_OPTIONS}
        placeholder="Select"
      />
    </div>
  );
}

export default JobAndSeniorityFilterContent;
