import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider } from "antd5";
import { CheckboxProps } from "antd5/lib";

import SignalEmptyState from "components/form_components/SignalEmptyState";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import Signal from "../../../../lib/icons/Signal";
import { SupplierOption } from "./SuppliersDropdown";

import css from "./SignalSuppliersChecklist.module.scss";

type Props = {
  category: "Competitor" | "Partner";
  options: SupplierOption[];
  onChange: (suppliers: SupplierOption[], flag: "add" | "remove") => void;
  value: string[];
  backBtnFn: () => void;
};

function SignalSuppliersChecklist({
  category,
  options: suppliers,
  onChange,
  value,
  backBtnFn,
}: Props) {
  // Checkbox options
  const checkedList = value ? suppliers.filter((s) => value.includes(s.value)) : [];
  const isAllSuppliersChecked = suppliers.length === checkedList.length;
  const isSomeSuppliersChecked = checkedList.length > 0 && checkedList.length < suppliers.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      onChange(suppliers, "add");
    } else {
      onChange(suppliers, "remove");
    }
  };

  const emptyStateCategory = category.toLowerCase();

  const backBtn = (
    <Button
      onClick={() => backBtnFn()}
      className={css.backBtn}
      icon={<LeftOutlined />}
      iconPosition="start"
    >
      Back
    </Button>
  );

  return suppliers.length === 0 ? (
    <>
      <div className={css.optionsHeader}>{backBtn}</div>
      <SignalEmptyState
        entity={emptyStateCategory}
        message={`Save suppliers as ${emptyStateCategory}s through your signal settings to uncover more renewal opportunities and achieve relevant results.`}
      />
    </>
  ) : (
    <div id="scrollableDiv" className={css.scrollableDiv} aria-label="scrollableDiv">
      <div className={css.optionsHeader}>
        {backBtn}
        <label className={css.labelAndIcon}>
          <Checkbox
            aria-label="Select all"
            indeterminate={isSomeSuppliersChecked}
            onChange={onCheckAllChange}
            checked={isAllSuppliersChecked}
            className={css.label}
          >
            Select all {category.toLowerCase()}s
          </Checkbox>
        </label>
        <Divider className={css.divider} />
      </div>

      <div className={css.checkboxGroup}>
        {suppliers.map((supplier) => (
          <React.Fragment key={supplier.value}>
            <label className={css.labelAndIcon}>
              <Checkbox
                value={supplier.value}
                name={supplier.label}
                checked={value.includes(supplier.value)}
                className={css.label}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  const selectedOptions = suppliers.filter((s) => value.includes(s.value));

                  if (isChecked) {
                    onChange([...selectedOptions, supplier], "add");
                  } else {
                    onChange(
                      selectedOptions.filter((s) => s.value === supplier.value),
                      "remove",
                    );
                  }
                }}
                aria-label="Supplier option"
              >
                <EllipsisTooltipText fullText={supplier.label} containerClassname={css.labelText} />
              </Checkbox>
              <Signal className={css.signalIcon} size={16} label="signalIcon" />
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default SignalSuppliersChecklist;
