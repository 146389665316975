import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import FilterFormTitle from "components/filter_form/filter_form_title/FilterFormTitle";
import { countFilters } from "components/filter_form/utils";
import { camelToSentence } from "lib/utils";
import FilterCollapse from "../filter_form/filter_collapse/FilterCollapse";
import FilterCollapseHeader from "../filter_form/filter_collapse_header/FilterCollapseHeader";
import { ContactDisplayOption, ContactFilters } from "./contactUtils";
import { contactFilterContents } from "./filter_groups";
import { EMPTY_CONTACT_FILTERS } from "./utils";

import css from "./FilterForm.module.scss";

type FilterGroupKeys = keyof typeof contactFilterContents;

export const groupedKeys: Record<FilterGroupKeys, (keyof ContactFiltersComplete)[]> = {
  jobTitleAndSeniority: ["jobTitle", "seniority", "jobFunction"],
  contactInformation: ["contactDisplayOptionsEmail", "contactDisplayOptionsPhone"],
  buyers: ["buyerListIds", "buyerGuids", "buyerCategoryIds"],
  contactName: ["firstName", "lastName"],
};

export type ContactFiltersComplete = ContactFilters & {
  contactDisplayOptionsEmail: boolean;
  contactDisplayOptionsPhone: boolean;
};

export function FilterForm({
  onChange,
  filters,
  onClose,
  activeGroups,
  onActiveGroupChange,
}: {
  onChange: (filters: ContactFilters) => void;
  filters: Record<string, unknown>;
  onClose: () => void;
  activeGroups: string[];
  onActiveGroupChange: (group: string[]) => void;
}) {
  const previous = useRef(filters);
  const { control, watch, handleSubmit, reset, setValue } = useForm<ContactFiltersComplete>({
    mode: "onChange",
    defaultValues: filters,
  });

  const data = watch();

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((data) => {
        const { contactDisplayOptionsEmail, contactDisplayOptionsPhone, ...restData } = data;

        const contactDisplayOptions: ContactDisplayOption[] = [];
        contactDisplayOptionsEmail && contactDisplayOptions.push("EMAIL");
        contactDisplayOptionsPhone && contactDisplayOptions.push("PHONE");

        const parsedData: ContactFilters = {
          ...restData,
          contactDisplayOptions,
        };

        previous.current = { data };
        onChange(parsedData);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [watch, handleSubmit, onChange, filters]);

  function clearAll() {
    reset({ ...EMPTY_CONTACT_FILTERS });
  }

  const filterProps = {
    control,
    data,
    isBuyerIdsFilterApplied: !!filters.buyerIds,
  };

  const counts = countFilters(filters, groupedKeys);

  function clearFilter(key: FilterGroupKeys) {
    const keys = groupedKeys[key];
    keys.forEach((k) => {
      setValue(k, EMPTY_CONTACT_FILTERS[k]);
    });
  }

  function getFilterGroups() {
    const items = (Object.keys(groupedKeys) as FilterGroupKeys[]).map((key) => {
      const ContentComponent = contactFilterContents[key];

      return {
        key,
        header: (
          <FilterCollapseHeader
            title={camelToSentence(key)}
            count={counts[key]}
            clear={() => clearFilter(key)}
          />
        ),
        content: <ContentComponent {...filterProps} />,
      };
    });

    return items;
  }

  const items = getFilterGroups();

  return (
    <form className={css.form} aria-label="Contact filters">
      <FilterFormTitle
        clearFilters={clearAll}
        onClose={onClose}
        showClear={Object.values(counts).some(Boolean)}
      />
      <FilterCollapse items={items} value={activeGroups} onChange={onActiveGroupChange} />
    </form>
  );
}
