import * as React from "react";
import classnames from "classnames";

import css from "./Badge.module.scss";

type BadgeProps = React.PropsWithChildren<{
  hide?: boolean;
}>;

export default function Badge({ children, hide }: BadgeProps): JSX.Element {
  return <span className={classnames(css.badge, hide ? css.hidden : undefined)}>{children}</span>;
}
