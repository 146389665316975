/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResolveOrganisationRequest
 */
export interface ResolveOrganisationRequest {
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationRequest
     */
    primaryRole?: ResolveOrganisationRequestPrimaryRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationRequest
     */
    countryCode: string;
}


/**
 * @export
 */
export const ResolveOrganisationRequestPrimaryRoleEnum = {
    Buyer: 'Buyer',
    Supplier: 'Supplier'
} as const;
export type ResolveOrganisationRequestPrimaryRoleEnum = typeof ResolveOrganisationRequestPrimaryRoleEnum[keyof typeof ResolveOrganisationRequestPrimaryRoleEnum];


/**
 * Check if a given object implements the ResolveOrganisationRequest interface.
 */
export function instanceOfResolveOrganisationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

export function ResolveOrganisationRequestFromJSON(json: any): ResolveOrganisationRequest {
    return ResolveOrganisationRequestFromJSONTyped(json, false);
}

export function ResolveOrganisationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolveOrganisationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'primaryRole': !exists(json, 'primary_role') ? undefined : json['primary_role'],
        'countryCode': json['country_code'],
    };
}

export function ResolveOrganisationRequestToJSON(value?: ResolveOrganisationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'primary_role': value.primaryRole,
        'country_code': value.countryCode,
    };
}

