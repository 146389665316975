/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount } from './SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount';
import {
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSON,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSONTyped,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountToJSON,
} from './SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount';

/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs
 */
export interface SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs {
    /**
     * 
     * @type {Date}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs
     */
    lastActivity?: Date;
    /**
     * 
     * @type {number}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs
     */
    count: number;
    /**
     * 
     * @type {SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs
     */
    averageAmount: SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmount;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs interface.
 */
export function instanceOfSearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "averageAmount" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSON(json: any): SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs {
    return SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastActivity': !exists(json, 'lastActivity') ? undefined : (new Date(json['lastActivity'])),
        'count': json['count'],
        'averageAmount': SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountFromJSON(json['averageAmount']),
    };
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsToJSON(value?: SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lastActivity': value.lastActivity === undefined ? undefined : (value.lastActivity.toISOString()),
        'count': value.count,
        'averageAmount': SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsAverageAmountToJSON(value.averageAmount),
    };
}

