/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs } from './SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs';
import {
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSON,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSONTyped,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsToJSON,
} from './SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs';
import type { SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner } from './SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner';
import {
    SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSON,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSONTyped,
    SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerToJSON,
} from './SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner';

/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsResponseOrganisationsInner
 */
export interface SearchFrameworkOrganisationsResponseOrganisationsInner {
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInner
     */
    name: string;
    /**
     * 
     * @type {Array<SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner>}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInner
     */
    categories: Array<SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInner
     */
    roles: Array<string>;
    /**
     * 
     * @type {SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInner
     */
    callOffs?: SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffs;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsResponseOrganisationsInner interface.
 */
export function instanceOfSearchFrameworkOrganisationsResponseOrganisationsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSON(json: any): SearchFrameworkOrganisationsResponseOrganisationsInner {
    return SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsResponseOrganisationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'categories': ((json['categories'] as Array<any>).map(SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSON)),
        'roles': json['roles'],
        'callOffs': !exists(json, 'callOffs') ? undefined : SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsFromJSON(json['callOffs']),
    };
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerToJSON(value?: SearchFrameworkOrganisationsResponseOrganisationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'categories': ((value.categories as Array<any>).map(SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerToJSON)),
        'roles': value.roles,
        'callOffs': SearchFrameworkOrganisationsResponseOrganisationsInnerCallOffsToJSON(value.callOffs),
    };
}

