/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecutePromptRequest
 */
export interface ExecutePromptRequest {
    /**
     * 
     * @type {string}
     * @memberof ExecutePromptRequest
     */
    promptTemplate: string;
    /**
     * 
     * @type {object}
     * @memberof ExecutePromptRequest
     */
    variables: object;
    /**
     * 
     * @type {string}
     * @memberof ExecutePromptRequest
     */
    context?: string;
}

/**
 * Check if a given object implements the ExecutePromptRequest interface.
 */
export function instanceOfExecutePromptRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "promptTemplate" in value;
    isInstance = isInstance && "variables" in value;

    return isInstance;
}

export function ExecutePromptRequestFromJSON(json: any): ExecutePromptRequest {
    return ExecutePromptRequestFromJSONTyped(json, false);
}

export function ExecutePromptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecutePromptRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'promptTemplate': json['prompt_template'],
        'variables': json['variables'],
        'context': !exists(json, 'context') ? undefined : json['context'],
    };
}

export function ExecutePromptRequestToJSON(value?: ExecutePromptRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prompt_template': value.promptTemplate,
        'variables': value.variables,
        'context': value.context,
    };
}

