import React from "react";
import { Control } from "react-hook-form";

import { Checkbox } from "components/form_components/Inputs";
import { ContactFiltersComplete } from "../FilterForm";

import css from "./SharedFilterGroups.module.scss";

export interface ContactInformationContentProps {
  control: Control<ContactFiltersComplete, unknown>;
}

function ContactInformationFilterContent({ control }: ContactInformationContentProps) {
  return (
    <div className={css.filterSection} aria-label="Contact information filters">
      <Checkbox
        name="contactDisplayOptionsEmail"
        label=""
        subLabel="Must contain"
        fieldLabel={"Email"}
        control={control}
        value="EMAIL"
      />
      <Checkbox
        name="contactDisplayOptionsPhone"
        label=""
        fieldLabel={"Phone"}
        control={control}
        value="PHONE"
      />
    </div>
  );
}

export default ContactInformationFilterContent;
