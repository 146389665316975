import React, { useCallback, useMemo, useState } from "react";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, message, Pagination, TourProps } from "antd5";
import classNames from "classnames";

import RequestContactsButton from "components/actions/RequestContactsButton";
import { DetailsContent } from "components/app_layout/DetailsLayout";
import { StartTourModal } from "components/onboarding/StartTourModal";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import RedactedWrapper, { RedactedLink } from "lib/core_components/RedactedWrapper";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import { ColumnSizeConfig, ColumnType } from "lib/core_components/Table/ColumnTypes";
import { tableRowSelection } from "lib/core_components/Table/Table";
import TableBanner from "lib/core_components/TableBanner";
import { TourHeader } from "lib/core_components/TourHeader";
import { createUseDebounce, useDebouncedValue } from "lib/debounce";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { Contact } from "lib/generated/app-service-gql/graphql";
import { useBuyers } from "lib/hooks/api/buyer/useBuyers";
import { useContacts } from "lib/hooks/api/contacts/useContacts";
import { useUpdateRecordQualification } from "lib/hooks/api/records/useUpdateRecordQualification";
import { useProductTour } from "lib/hooks/useProductTour";
import { useURLState } from "lib/hooks/useURLState";
import { PagingState } from "lib/search/types";
import { EventData, EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { getSignalTypes, RecordDetails } from "lib/types/models";
import { ContactsFilterBar } from "./ContactsFilterBar";
import {
  BuyerDetailsCell,
  ContactInformationCell,
  ContactNameCell,
  ContactsTableEmpty,
} from "./ContactsTableUtils";
import {
  ContactFilters,
  convertFiltersToSearchContactsRequest,
  DEFAULT_CONTACT_FILTERS,
  parseContactsFilters,
} from "./contactUtils";

import css from "./ContactsTable.module.scss";

import outreachOnboarding from "../../../assets/images/onboarding/outreach.gif";

const CONTACTS_DATA_TYPE = "CONTACTS";

const BASIC_SIZE: ColumnSizeConfig = {
  small: 150,
  medium: 150,
  large: 175,
  xlarge: 350,
};

const MEDIUM_SIZE: ColumnSizeConfig = {
  small: 200,
  medium: 225,
  large: 225,
  xlarge: 500,
};

const LARGE_SIZE: ColumnSizeConfig = {
  small: 350,
  medium: 350,
  large: 350,
  xlarge: 500,
};

type ContactsTableProps = {
  // If this prop is provided, then contacts are searched for already within thr context of a buyer
  buyerId?: string;
  addContacts?: (contacts: Contact[]) => void;

  // Want to make this prop required so we have complete tracking of this reusable component
  contextSource: string;
  pageSize: number;

  showAllFiltersDrawer: boolean;
  showDropdownFilters: boolean;

  record?: RecordDetails;
};

function ContactsTable({
  buyerId,
  addContacts,
  contextSource,
  pageSize,
  showAllFiltersDrawer,
  showDropdownFilters,
  record,
}: ContactsTableProps) {
  const {
    tourOpen: relatedContactTourOpen,
    permanentlyHide,
    temporarilyHide,
  } = useProductTour(
    ProductTourCompletionStateResponseTourStateTourIdentifierEnum.RelatedContactsIntro,
    {
      delayMsOnFirstMount: 350,
      disableTour: !FeatureToggles.isEnabled(Feature.RELATED_CONTACTS_TOUR) || !record,
    },
  );

  const [showingRelatedContactsTour, setShowRelatedContactsTour] = useState(false);
  const useDebounce300 = createUseDebounce(300);

  // Only used if within the context of a record (RelatedContactsPage component)
  const { mutate: updateRecordQualification } = useUpdateRecordQualification({
    onError: () => message.error("Error updating record qualification"),
  });

  const [pagingState, setPagingState] = React.useState<PagingState>({
    currentPage: 1,
    pageSize: pageSize,
  });

  // If we are within the context of a buyer, then the default filters must always reflect this
  const defaultContactFilters = useMemo(
    () =>
      buyerId ? { ...DEFAULT_CONTACT_FILTERS, buyerGuids: [buyerId] } : DEFAULT_CONTACT_FILTERS,
    [buyerId],
  );

  const [contactFilters = defaultContactFilters, setContactFilters] = useURLState<ContactFilters>(
    "contactsFilters",
    defaultContactFilters,
    (params) => parseContactsFilters(params, defaultContactFilters),
  );

  const [selectedRowGuids, setSelectedRowGuids] = React.useState<string[]>([]);

  const [debouncedContactFilters] = useDebouncedValue<ContactFilters>(contactFilters);

  const [showTooltipRowIndex, setShowTooltipRowIndex] = React.useState<number>();

  const filterRef = React.useRef(null);

  const [outreachBtnRef, setOutreachBtnRef] = React.useState<React.MutableRefObject<null>>(
    React.createRef(),
  );
  const [crmButtonRef, setCrmBtnRef] = React.useState<React.MutableRefObject<null>>(
    React.createRef(),
  );

  const trackOnboarding = useCallback(
    (eventName: EventNames, action: string, tourIdentifier: string) => {
      const eventData: EventData = {
        "Context source": contextSource,
        Action: action,
        "Tour identifier": tourIdentifier,
      };

      logEvent(eventName, eventData);
    },
    [contextSource],
  );

  const onShowRelatedContactsTour = React.useCallback(
    (show: boolean) => {
      setShowRelatedContactsTour(show);

      if (show) {
        trackOnboarding(EventNames.productTourActioned, "Start tour", "Related Contacts Intro");
      } else {
        trackOnboarding(EventNames.productTourHidden, "Dismiss", "Related Contacts Intro");
      }
    },
    [trackOnboarding],
  );

  const relatedContactsSteps: TourProps["steps"] = [
    {
      title: <TourHeader headerText="Find the most relevant contacts for you..." />,
      description:
        "Search by job title and apply filters to find the types of decision makers that are most relevant to you and your business.",
      nextButtonProps: {
        className: css.nextButton,
      },
      prevButtonProps: {
        children: "Back",
        className: css.backButton,
      },
      target: filterRef.current,
    },
    {
      title: <TourHeader headerText="Create personalised outreach" />,
      description:
        "Engage with decision makers in minutes with personalised and eye catching messaging.",
      nextButtonProps: {
        className: css.nextButton,
      },
      prevButtonProps: {
        children: "Back",
        className: css.backButton,
      },
      cover: <img src={outreachOnboarding} alt="outreach demo" className={css.outreachDemoImage} />,
      target: outreachBtnRef.current,
      placement: "right",
      mask: {
        style: { pointerEvents: "none" },
      },
    },
    {
      title: <TourHeader headerText="Keep everything in sync" />,
      description:
        "Send relevant contact directly to your crm to keep track of your work in one place.",
      nextButtonProps: {
        className: css.nextButton,
        children: "Done",
      },
      prevButtonProps: {
        children: "Back",
        className: css.backButton,
      },
      target: crmButtonRef.current,
      mask: {
        style: { pointerEvents: "none" },
      },
    },
  ];

  const {
    data: contactsData,
    isLoading,
    isFetching,
  } = useContacts({
    ...convertFiltersToSearchContactsRequest(
      debouncedContactFilters,
      pagingState.pageSize,
      pagingState.currentPage,
    ),
  });

  /**
   * If within the context of a record (in RelatedContacts component) then this callback will update the qualfication status of that record,
   * will also move the contact to actioned (when we add that functionality)
   */
  const updateStatus = React.useCallback(() => {
    if (record) {
      updateRecordQualification({
        recordGuid: record.guid,
        score: record.relevance_score,
        signalTypes: getSignalTypes(record.signals),
        procurementStage: {
          id: record.procurement_stage.id,
          stage: record.procurement_stage.stage,
        },
        qualification: "pre_engage_done",
        contextSource,
      });
    }

    // TODO: Add code to update contact to "Actioned" here when we add that in
  }, [record, updateRecordQualification, contextSource]);

  /**
   * Accordingly updates the buyers state when contactsData changes.
   * Only necessary when not within the context of a buyer profile
   */
  const buyerGuids = new Set(
    contactsData?.contacts.results.filter((x) => x.buyer?.id).map((x) => x.buyer?.id as string),
  );

  const { data: buyers } = useBuyers(
    { guids: Array.from(buyerGuids) },
    { enabled: buyerGuids.size > 0 && !buyerId },
  );

  /**
   * Setting the refs as a state so that they do not change on every render
   */
  const setOutreachRef = useCallback(
    (outreachRef: React.MutableRefObject<null>) =>
      setOutreachBtnRef((oldRef) => (!oldRef.current ? outreachRef : oldRef)),

    [],
  );

  const setCrmRef = useCallback(
    (crmRef: React.MutableRefObject<null>) =>
      setCrmBtnRef((oldRef) => (!oldRef.current ? crmRef : oldRef)),
    [],
  );

  const onChangeSearchFilters = useDebounce300((filters: Partial<ContactFilters>) => {
    setContactFilters((oldFilters) => ({ ...oldFilters, ...filters }));
    setPagingState((oldPagingState) => ({ ...oldPagingState, currentPage: 1 }));
  });

  const columns = React.useMemo((): ColumnType<Contact>[] => {
    const columns: ColumnType<Contact>[] = [
      {
        key: "name",
        title: "Job title & Contact name",
        render: (t: unknown, c: Contact, index: number) => (
          <ContactNameCell
            contact={c}
            record={record}
            rowIndex={index}
            showTooltipRowIndex={showTooltipRowIndex}
            setShowTooltipRowIndex={setShowTooltipRowIndex}
            setOutreachRef={setOutreachRef}
            setCrmRef={setCrmRef}
            showFirstCellActions={showingRelatedContactsTour && !!record}
          />
        ),
        sizeConfig: LARGE_SIZE,
      },
      {
        title: <EllipsisTooltipText fullText="Contact information" />,
        key: "contactInformation",
        sizeConfig: MEDIUM_SIZE,
        render: (_: unknown, c: Contact) => (
          <RedactedWrapper
            requiredDataType={CONTACTS_DATA_TYPE}
            featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : undefined}
            contextSource="In-row"
            redactContent={
              <div className={css.contactCellContent}>
                <span className={css.contactDetail}>
                  <MailOutlined className={css.contactIcon} />
                  <RedactedLink textToRedact={c.email || "john@fakemail.com"} />
                </span>
                <span className={css.contactDetail}>
                  <PhoneOutlined className={css.contactIcon} />
                  <RedactedLink textToRedact={c.phone || "123-456 78911"} />
                </span>
              </div>
            }
          >
            <ContactInformationCell contact={c} onCopy={updateStatus} />
          </RedactedWrapper>
        ),
      },
      {
        title: "Seniority",
        key: "seniority",
        dataIndex: "seniority",
        sizeConfig: BASIC_SIZE,
        render: (_: unknown, c: Contact) => (
          <EllipsisTooltipText fullText={c.seniority} containerClassname={css.elipsisText} />
        ),
      },
      {
        title: "Function",
        key: "function",
        dataIndex: "jobFunction",
        sizeConfig: BASIC_SIZE,
        render: (_: unknown, c: Contact) => (
          <EllipsisTooltipText
            fullText={c.jobFunction.join(", ")}
            containerClassname={css.elipsisText}
          />
        ),
      },
    ];

    if (!buyerId) {
      columns.splice(2, 0, {
        title: "Buyer",
        key: "buyerGuid",
        render: (_: unknown, c: Contact) => (
          <BuyerDetailsCell
            contact={c}
            buyer={buyers?.results.find((x) => x.guid === c.buyer?.id)}
          />
        ),
        sizeConfig: MEDIUM_SIZE,
      });
    }

    return columns;
  }, [
    buyerId,
    record,
    showTooltipRowIndex,
    setOutreachRef,
    setCrmRef,
    showingRelatedContactsTour,
    updateStatus,
    buyers?.results,
  ]);

  return (
    <TrackingProvider
      data={{
        "Context source": contextSource,
      }}
    >
      <span>
        {addContacts && (
          <Button
            danger
            type="primary"
            disabled={selectedRowGuids.length === 0}
            onClick={() =>
              addContacts(
                contactsData?.contacts.results.filter((x) => selectedRowGuids.includes(x.id)) ?? [],
              )
            }
          >
            Add Contacts ({selectedRowGuids.length})
          </Button>
        )}
        <ContactsFilterBar
          contactFilters={contactFilters}
          onChangeSearchFilters={onChangeSearchFilters}
          selectedRowGuids={selectedRowGuids}
          totalResults={contactsData?.contacts.totalResults ?? 0}
          hideBuyerFilters={!!buyerId}
          defaultFilters={defaultContactFilters}
          showAllFiltersDrawer={showAllFiltersDrawer}
          showDropdownFilters={showDropdownFilters}
          filterRef={filterRef}
          showingRelatedContactsTour={showingRelatedContactsTour}
          buyers={buyers?.results}
        />
      </span>
      <DetailsContent
        className={classNames({
          [css.removeSpacing]: !!buyerId,
        })}
      >
        {isLoading ? (
          <SkeletonTable
            columns={columns}
            pageSize={pagingState.pageSize}
            rowCount={pagingState.pageSize}
            active
          />
        ) : contactsData?.contacts && contactsData.contacts.results.length > 0 ? (
          <>
            <Table<Contact>
              ariaLabel="Contacts Table"
              columns={columns}
              dataSource={contactsData?.contacts.results || []}
              loading={isFetching}
              className={css.hiddenOverflow}
              rowKey={(row) => row.id}
              pagination={false}
              rowSelection={tableRowSelection(
                selectedRowGuids,
                setSelectedRowGuids,
                contactsData?.contacts.results || [],
                "id",
              )}
            />
            {!window.guestUser && (
              <TableBanner
                title="Not found any relevant contacts?"
                description="Just send a request below and a member of the team can look into a couple of other sources for you."
                ctaElement={
                  <RequestContactsButton
                    contactFilters={contactFilters}
                    contextSource="No relevant contacts banner"
                  />
                }
              />
            )}
            <Pagination
              className={css.pagination}
              current={pagingState.currentPage}
              defaultPageSize={pagingState.pageSize}
              total={contactsData?.contacts.totalResults ? contactsData.contacts.totalResults : 0}
              onChange={(page: number) => {
                setPagingState({ currentPage: page, pageSize: pagingState.pageSize });
              }}
            />
          </>
        ) : (
          <ContactsTableEmpty contactFilters={contactFilters} />
        )}

        <StartTourModal
          // Only have tour in the related contacts page
          enabled={!!record && FeatureToggles.isEnabled(Feature.RELATED_CONTACTS_TOUR)}
          title="Take a tour of our contact engagement module"
          description="See how to find the most relevant contacts and take action to engage with them."
          tourSteps={relatedContactsSteps}
          tourOpen={relatedContactTourOpen}
          showTour={showingRelatedContactsTour}
          dismissTour={() => {
            temporarilyHide();
            onShowRelatedContactsTour(false);
          }}
          onShowTour={() => onShowRelatedContactsTour(true)}
          onHideTour={() => {
            permanentlyHide();
            onShowRelatedContactsTour(false);
          }}
        />
      </DetailsContent>
    </TrackingProvider>
  );
}

export default ContactsTable;
