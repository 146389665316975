/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchEntitiesRequest
 */
export interface SearchEntitiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchEntitiesRequest
     */
    names: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchEntitiesRequest
     */
    entityType?: SearchEntitiesRequestEntityTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesRequest
     */
    stotlesScoreThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesRequest
     */
    maxResultsCount?: number;
}


/**
 * @export
 */
export const SearchEntitiesRequestEntityTypeEnum = {
    Buyer: 'buyer',
    Supplier: 'supplier'
} as const;
export type SearchEntitiesRequestEntityTypeEnum = typeof SearchEntitiesRequestEntityTypeEnum[keyof typeof SearchEntitiesRequestEntityTypeEnum];


/**
 * Check if a given object implements the SearchEntitiesRequest interface.
 */
export function instanceOfSearchEntitiesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "names" in value;

    return isInstance;
}

export function SearchEntitiesRequestFromJSON(json: any): SearchEntitiesRequest {
    return SearchEntitiesRequestFromJSONTyped(json, false);
}

export function SearchEntitiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'names': json['names'],
        'entityType': !exists(json, 'entity_type') ? undefined : json['entity_type'],
        'stotlesScoreThreshold': !exists(json, 'stotles_score_threshold') ? undefined : json['stotles_score_threshold'],
        'maxResultsCount': !exists(json, 'max_results_count') ? undefined : json['max_results_count'],
    };
}

export function SearchEntitiesRequestToJSON(value?: SearchEntitiesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'names': value.names,
        'entity_type': value.entityType,
        'stotles_score_threshold': value.stotlesScoreThreshold,
        'max_results_count': value.maxResultsCount,
    };
}

