import * as React from "react";
import Button, { ButtonProps } from "antd5/lib/button";

export type Props = {
  onAction: () => Promise<void>;
} & Omit<ButtonProps, "onClick">;

function ActionButton(props: Props): JSX.Element {
  const { onAction, ...buttonProps } = props;
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleClick = React.useCallback(async () => {
    setIsProcessing(true);
    try {
      await onAction();
    } finally {
      setIsProcessing(false);
    }
  }, [onAction]);
  return (
    <Button {...buttonProps} loading={isProcessing || buttonProps.loading} onClick={handleClick} />
  );
}

export default ActionButton;
