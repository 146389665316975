/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner
 */
export interface SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner {
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner
     */
    buyerCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner
     */
    source: string;
}

/**
 * Check if a given object implements the SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner interface.
 */
export function instanceOfSearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerCategoryId" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSON(json: any): SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner {
    return SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSONTyped(json, false);
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerCategoryId': json['buyerCategoryId'],
        'source': json['source'],
    };
}

export function SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInnerToJSON(value?: SearchFrameworkOrganisationsResponseOrganisationsInnerCategoriesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyerCategoryId': value.buyerCategoryId,
        'source': value.source,
    };
}

