/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExecuteCompletionResponse
 */
export interface ExecuteCompletionResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExecuteCompletionResponse
     */
    llmResponse: Array<string>;
}

/**
 * Check if a given object implements the ExecuteCompletionResponse interface.
 */
export function instanceOfExecuteCompletionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "llmResponse" in value;

    return isInstance;
}

export function ExecuteCompletionResponseFromJSON(json: any): ExecuteCompletionResponse {
    return ExecuteCompletionResponseFromJSONTyped(json, false);
}

export function ExecuteCompletionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExecuteCompletionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'llmResponse': json['llm_response'],
    };
}

export function ExecuteCompletionResponseToJSON(value?: ExecuteCompletionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'llm_response': value.llmResponse,
    };
}

