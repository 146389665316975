/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { HealthControllerReadiness200ResponseInfoValue } from './HealthControllerReadiness200ResponseInfoValue';
import {
    HealthControllerReadiness200ResponseInfoValueFromJSON,
    HealthControllerReadiness200ResponseInfoValueFromJSONTyped,
    HealthControllerReadiness200ResponseInfoValueToJSON,
} from './HealthControllerReadiness200ResponseInfoValue';

/**
 * 
 * @export
 * @interface HealthControllerReadiness200Response
 */
export interface HealthControllerReadiness200Response {
    /**
     * 
     * @type {string}
     * @memberof HealthControllerReadiness200Response
     */
    status?: string;
    /**
     * 
     * @type {{ [key: string]: HealthControllerReadiness200ResponseInfoValue; }}
     * @memberof HealthControllerReadiness200Response
     */
    info?: { [key: string]: HealthControllerReadiness200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerReadiness200ResponseInfoValue; }}
     * @memberof HealthControllerReadiness200Response
     */
    error?: { [key: string]: HealthControllerReadiness200ResponseInfoValue; } | null;
    /**
     * 
     * @type {{ [key: string]: HealthControllerReadiness200ResponseInfoValue; }}
     * @memberof HealthControllerReadiness200Response
     */
    details?: { [key: string]: HealthControllerReadiness200ResponseInfoValue; };
}

/**
 * Check if a given object implements the HealthControllerReadiness200Response interface.
 */
export function instanceOfHealthControllerReadiness200Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthControllerReadiness200ResponseFromJSON(json: any): HealthControllerReadiness200Response {
    return HealthControllerReadiness200ResponseFromJSONTyped(json, false);
}

export function HealthControllerReadiness200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthControllerReadiness200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
        'info': !exists(json, 'info') ? undefined : (json['info'] === null ? null : mapValues(json['info'], HealthControllerReadiness200ResponseInfoValueFromJSON)),
        'error': !exists(json, 'error') ? undefined : (json['error'] === null ? null : mapValues(json['error'], HealthControllerReadiness200ResponseInfoValueFromJSON)),
        'details': !exists(json, 'details') ? undefined : (mapValues(json['details'], HealthControllerReadiness200ResponseInfoValueFromJSON)),
    };
}

export function HealthControllerReadiness200ResponseToJSON(value?: HealthControllerReadiness200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'info': value.info === undefined ? undefined : (value.info === null ? null : mapValues(value.info, HealthControllerReadiness200ResponseInfoValueToJSON)),
        'error': value.error === undefined ? undefined : (value.error === null ? null : mapValues(value.error, HealthControllerReadiness200ResponseInfoValueToJSON)),
        'details': value.details === undefined ? undefined : (mapValues(value.details, HealthControllerReadiness200ResponseInfoValueToJSON)),
    };
}

