import { DefaultOptionType } from "antd5/es/cascader";

import { ContactFiltersComplete } from "./FilterForm";

export const SENIORITY_OPTIONS: DefaultOptionType[] = [
  {
    label: "Chairman",
    value: "Chairman",
  },
  {
    label: "Chief Officer",
    value: "Chief Officer",
  },
  {
    label: "Clerk/Secretary",
    value: "Clerk/Secretary",
  },
  {
    label: "Deputy/Assistant Chief Officer",
    value: "Deputy/Assistant Chief Officer",
  },
  {
    label: "Second Level / Head of Service",
    value: "Second Level / Head of Service",
  },
  {
    label: "Third Level/Head of Service/Manager",
    value: "Third Level/Head of Service/Manager",
  },
  {
    label: "Top Level Director",
    value: "Top Level Director",
  },
  {
    label: "Unspecified",
    value: "Unspecified",
  },
  {
    label: "Vice/Deputy Chairman",
    value: "Vice/Deputy Chairman",
  },
];

export const JOB_FUNCTION_OPTIONS: DefaultOptionType[] = [
  {
    label: "Admin & Support",
    value: "Admin & Support",
  },
  {
    label: "Educator",
    value: "Educator",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Health Services",
    value: "Health Services",
  },
  {
    label: "HR & Training",
    value: "HR & Training",
  },
  {
    label: "IT & Digital",
    value: "IT & Digital",
  },
  {
    label: "Legal & Compliance",
    value: "Legal & Compliance",
  },
  {
    label: "Marketing, Communications, & Sales",
    value: "Marketing, Communications, & Sales",
  },
  {
    label: "Operations",
    value: "Operations",
  },
  {
    label: "Procurement",
    value: "Procurement",
  },
  {
    label: "Service Delivery",
    value: "Service Delivery",
  },
  {
    label: "Uncategorised",
    value: "Uncategorised",
  },
];

export const EMPTY_CONTACT_FILTERS: ContactFiltersComplete & {
  sort: { field: string; order: "ASC" | "DESC" };
} = {
  sort: {
    field: "job_title",
    order: "ASC",
  },
  jobTitle: "",
  firstName: "",
  lastName: "",
  seniority: [],
  jobFunction: [],
  buyerGuids: [],
  buyerListIds: [],
  buyerCategoryIds: [],
  includeUncategorisedBuyers: false,
  contactDisplayOptions: [],
  contactDisplayOptionsEmail: false,
  contactDisplayOptionsPhone: false,
};
