import React, { ReactNode } from "react";
import { Modal } from "antd5";

import { useURLState } from "../../lib/hooks/useURLState";
import { EventNames, useTracking } from "../../lib/tracking";
import { formatDataType } from "../../lib/utils";
import BookADemoForm from "./BookADemoForm";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./PaywallCTAModal.module.scss";

function BookADemoFromUrl({ children }: { children: ReactNode }) {
  const [bookADemoOpen, setBookADemoOpen] = useURLState<string | undefined>("bookADemo", undefined);

  const [dataType, setDataType] = useURLState("dataType", undefined);
  const contextSource = bookADemoOpen;

  const trackingData = {
    "Context source": contextSource,
    "Data type": formatDataType(dataType) ?? formatDataType(window.location.href) ?? "N/A",
  };

  const { logEvent } = useTracking();

  function onClose() {
    setDataType(undefined);
    setBookADemoOpen(undefined);
  }

  if (!bookADemoOpen) {
    return <>{children}</>;
  }

  if (bookADemoOpen) {
    logEvent(EventNames.paywallActioned, { ...trackingData, Action: "Book a demo in-app" });
  }

  return (
    <>
      <Modal
        open={!!bookADemoOpen}
        onCancel={() => {
          if (bookADemoOpen) {
            logEvent(EventNames.bookADemoCancelled, trackingData);
          }

          onClose();
        }}
        footer={null}
        className={css.bookADemoPaywallCTAModal}
        classNames={{
          content: css.bookADemoModalContent,
        }}
        centered
        zIndex={10000}
      >
        <BookADemoForm trackingData={trackingData} />
      </Modal>
      {children}
    </>
  );
}

export default BookADemoFromUrl;
