/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForwardGeolocationResponse
 */
export interface ForwardGeolocationResponse {
    /**
     * 
     * @type {string}
     * @memberof ForwardGeolocationResponse
     */
    lat: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardGeolocationResponse
     */
    lng: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardGeolocationResponse
     */
    response: string;
}

/**
 * Check if a given object implements the ForwardGeolocationResponse interface.
 */
export function instanceOfForwardGeolocationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lng" in value;
    isInstance = isInstance && "response" in value;

    return isInstance;
}

export function ForwardGeolocationResponseFromJSON(json: any): ForwardGeolocationResponse {
    return ForwardGeolocationResponseFromJSONTyped(json, false);
}

export function ForwardGeolocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwardGeolocationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lng': json['lng'],
        'response': json['response'],
    };
}

export function ForwardGeolocationResponseToJSON(value?: ForwardGeolocationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lng': value.lng,
        'response': value.response,
    };
}

