import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { App, Button, Dropdown, MenuProps, Tooltip } from "antd5";

import { SupplierExportRequestFormatEnum } from "lib/generated/app-api";
import { useExportSuppliers } from "lib/hooks/api/suppliers/useExportSuppliers";
import { useSuppliers } from "lib/hooks/api/suppliers/useSuppliers";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { blue400 } from "lib/themes/colors";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { downloadFile } from "lib/utils";
import { useSupportChat } from "lib/utils/useSupportChat";
import { DEFAULT_PAGINATION } from "./SupplierTable";
import { convertSupplierFiltersToRequest, SupplierFilters } from "./utils";

import css from "./ExportSuppliersButton.module.scss";

const EXPORT_LIMIT = 500;

export function ExportSuppliersButton({
  filters,
  selectedSuppliers,
}: {
  filters: SupplierFilters;
  selectedSuppliers?: string[];
}) {
  const { openSupportChat } = useSupportChat();

  const { logEvent, trackingData } = useTracking();
  const { message } = App.useApp();

  const exportSuppliersTrackingData = { ...trackingData, "CTA actioned": "Export" };

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(exportSuppliersTrackingData);

  const suppliersFilters = convertSupplierFiltersToRequest(filters, DEFAULT_PAGINATION);
  const exportFilters =
    selectedSuppliers && selectedSuppliers.length > 0
      ? { guid: selectedSuppliers }
      : { ...suppliersFilters, limit: undefined };
  const { isLoading, data } = useSuppliers(suppliersFilters);
  const { mutate, isLoading: isExporting } = useExportSuppliers({
    onError: () => message.error("Failed to download file"),
    onSuccess: (data, variables) =>
      downloadFile(
        data,
        `stotles-suppliers-export-${new Date().toJSON().slice(0, 10)}.${variables?.format}`,
      ),
  });

  const exportCount =
    selectedSuppliers && selectedSuppliers.length > 0
      ? selectedSuppliers.length
      : data?.pagingInfo.totalResults;

  const menuItens: MenuProps["items"] = [
    {
      key: "xlsx",
      label: "Export to XLSX",
    },
    {
      key: "csv",
      label: "Export to CSV",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }: { key: SupplierExportRequestFormatEnum }) => {
    if (!window.guestUser) {
      logEvent(EventNames.exportedData, {
        Count: exportCount,
        Format: key,
        "Data type": EventDataTypes.supplier,
      });
      mutate({ filters: exportFilters, format: key });
    } else {
      openGuestAccessPassPaywall();
    }
  };

  const exportLimitReached = exportCount && exportCount > EXPORT_LIMIT;
  const hasExportPermission = window.currentUser?.has_export_permission;

  if (data && exportLimitReached && !hasExportPermission) {
    return (
      <Tooltip
        title={
          <span>
            {`Narrow results to ${EXPORT_LIMIT} notices or less to export. Need more? `}
            <br />
            <Button
              type="text"
              onClick={() => openSupportChat("Supplier search")}
              style={{ color: blue400, padding: 0 }}
            >
              Chat with our team
            </Button>{" "}
            to get started.
          </span>
        }
      >
        <Button icon={<DownloadOutlined className={css.downloadIcon} />} disabled>
          Export All {`(${exportCount})`}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Dropdown menu={{ items: menuItens, onClick }} disabled={isExporting}>
      <Button
        loading={isLoading || isExporting}
        disabled={isLoading || isExporting}
        icon={<DownloadOutlined className={css.downloadIcon} />}
      >
        Export
        {(selectedSuppliers === undefined || selectedSuppliers.length === 0) &&
          ` All (${exportCount || 0})`}
      </Button>
    </Dropdown>
  );
}
