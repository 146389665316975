import isEqual from "lodash.isequal";

import { Option } from "lib/core_components/MultiSelectDropdownList";
import { SearchContactsRequest } from "lib/generated/app-service-gql/graphql";
import { PagingState } from "lib/search/types";
import { JobFunction } from "lib/utils/oscarDataUtils";

export const SENIORITY_OPTIONS: Option<string>[] = [
  { title: "Chief Officer", value: "Chief Officer" },
  { title: "Unspecified", value: "Unspecified" },
  { title: "Chairman", value: "Chairman" },
  { title: "Top Level Director", value: "Top Level Director" },
  { title: "Second Level / Head of Service", value: "Second Level / Head of Service" },
  { title: "Clerk/Secretary", value: "Clerk/Secretary" },
  { title: "Vice/Deputy Chairman", value: "Vice/Deputy Chairman" },
  { title: "Third Level/Head of Service/Manager", value: "Third Level/Head of Service/Manager" },
  { title: "Deputy/Assistant Chief Officer", value: "Deputy/Assistant Chief Officer" },
];

export const CATEGORY_TYPES: Option<JobFunction>[] = [
  { title: "Admin & Support", value: "Admin & Support" },
  { title: "Educator", value: "Educator" },
  { title: "Finance", value: "Finance" },
  { title: "Health Service", value: "Health Services" },
  { title: "HR & Training", value: "HR & Training" },
  { title: "IT & Digital", value: "IT & Digital" },
  { title: "Legal & Compliance", value: "Legal & Compliance" },
  { title: "Marketing, Communications, & Sales", value: "Marketing, Communications, & Sales" },
  { title: "Operations", value: "Operations" },
  { title: "Procurement", value: "Procurement" },
  { title: "Service Delivery", value: "Service Delivery" },
  { title: "Uncategorised", value: "Uncategorised" },
];
export type ContactDisplayOption = "EMAIL" | "PHONE";

export const CONTACTS_DISPLAY: {
  title: string;
  value: ContactDisplayOption;
}[] = [
  { title: "Email", value: "EMAIL" },
  { title: "Phone", value: "PHONE" },
];

export type ContactFilters = {
  jobTitle: string;
  firstName: string;
  lastName: string;
  seniority: string[];
  jobFunction: JobFunction[];
  buyerGuids: string[];
  buyerListIds: string[];
  buyerCategoryIds: string[];
  includeUncategorisedBuyers: boolean;
  contactDisplayOptions: ContactDisplayOption[];
};

export type ContactParams = PagingState & ContactFilters;

export const DEFAULT_CONTACT_FILTERS: ContactFilters = {
  jobTitle: "",
  firstName: "",
  lastName: "",
  seniority: [],
  jobFunction: [],
  buyerGuids: [],
  buyerListIds: [],
  buyerCategoryIds: [],
  includeUncategorisedBuyers: false,
  contactDisplayOptions: [],
};

export const parseContactsFilters = (
  params: unknown,
  defaultContactFilters: ContactFilters,
): ContactFilters => {
  const newFilters = (params as Partial<ContactFilters>) || defaultContactFilters;

  // Converting params to Boolean is weird, hence doing this annoying workaround
  const includeUncatBuyers =
    newFilters.includeUncategorisedBuyers === undefined
      ? DEFAULT_CONTACT_FILTERS.includeUncategorisedBuyers
      : newFilters.includeUncategorisedBuyers.toString() === "true";

  return {
    ...DEFAULT_CONTACT_FILTERS,
    ...newFilters,
    includeUncategorisedBuyers: includeUncatBuyers,
    contactDisplayOptions: newFilters.contactDisplayOptions || [],
  };
};

export function contactFilterCount(
  filters: ContactFilters,
  defaultContactFilters: ContactFilters,
): number {
  let filterCount = 0;
  for (const [key, value] of Object.entries(filters)) {
    if (!isEqual(defaultContactFilters[key as keyof ContactFilters], value)) filterCount++;
  }
  return filterCount;
}

export function convertFiltersToSearchContactsRequest(
  filters: ContactFilters,
  limit?: number,
  page?: number,
): SearchContactsRequest {
  return {
    jobTitle: filters.jobTitle,
    firstName: filters.firstName,
    lastName: filters.lastName,
    seniority: filters.seniority.length > 0 ? filters.seniority : undefined,
    function: filters.jobFunction.length > 0 ? filters.jobFunction : undefined,
    buyerGuids: filters.buyerGuids.length > 0 ? filters.buyerGuids : undefined,
    buyerLists: filters.buyerListIds.length > 0 ? filters.buyerListIds : undefined,
    buyerCategoryIds: filters.buyerCategoryIds.length > 0 ? filters.buyerCategoryIds : undefined,
    mustContainEmail: filters.contactDisplayOptions.includes("EMAIL"),
    mustContainPhone: filters.contactDisplayOptions.includes("PHONE"),
    limit: limit || 20,
    page: page || 1,
  };
}
