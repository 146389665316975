import React from "react";
import { Dropdown, MenuProps } from "antd5";

import { TextLink } from "components/actions/Links";
import * as tracking from "lib/tracking";
import { useSupportChat } from "lib/utils/useSupportChat";
import { MenuButton, NavBarButton } from "./AppLayoutNavbar";

function HelpDropdown(): JSX.Element {
  const { openSupportChat } = useSupportChat();
  const menu: MenuProps = {
    items: [
      {
        key: "helpCentre",
        label: (
          <TextLink
            to="https://help.stotles.com"
            targetType="new-tab"
            eventName={tracking.EventNames.clickedHelpCentre}
            eventAttributes={{ "Context source": "navbar dropdown" }}
          >
            Help centre
          </TextLink>
        ),
      },
      {
        key: "playbooks",
        label: (
          <TextLink
            to="https://help.stotles.com/stotles-playbooks"
            targetType="new-tab"
            eventName={tracking.EventNames.clickedPlaybook}
            eventAttributes={{ "Context source": "navbar dropdown" }}
          >
            Playbooks
          </TextLink>
        ),
      },
      {
        key: "chatWithUs",
        label: (
          <MenuButton onClick={() => openSupportChat("Avatar Dropdown Menu")}>
            Chat with us
          </MenuButton>
        ),
      },
      // Currently this notion page isn't regularly updated. Commenting this out until Jody's more permanent solution is in place.
      // {
      //   key: "whatsNew",
      //   label: (
      //     <TextLink
      //       to="https://www.notion.so/stotles/What-s-New-in-Stotles-6aa53b4efab240a989fdfc4fe25e8c36"
      //       targetType="new-tab"
      //       eventName={tracking.EventNames.clickedWhatsNew}
      //       eventAttributes={{ "Context source": "navbar dropdown" }}
      //     >
      //       What's new
      //     </TextLink>
      //   ),
      // },
    ],
  };

  return (
    <Dropdown menu={menu} placement="bottomRight" trigger={["click"]}>
      <NavBarButton>Help</NavBarButton>
    </Dropdown>
  );
}

export default HelpDropdown;
