/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchRecordsRequestCursorInner } from './SearchRecordsRequestCursorInner';
import {
    SearchRecordsRequestCursorInnerFromJSON,
    SearchRecordsRequestCursorInnerFromJSONTyped,
    SearchRecordsRequestCursorInnerToJSON,
} from './SearchRecordsRequestCursorInner';

/**
 * 
 * @export
 * @interface SearchRecordsRequest
 */
export interface SearchRecordsRequest {
    /**
     * 
     * @type {object}
     * @memberof SearchRecordsRequest
     */
    query: object;
    /**
     * 
     * @type {boolean}
     * @memberof SearchRecordsRequest
     */
    disableHighlighting?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRecordsRequest
     */
    recordGuids: Array<string>;
    /**
     * Setting to empty array will trigger a search using each document's specific language. A non-empty array will limit search to documents indexed using the specified list of languages. Leave undefined for the legacy behaviour of treating all documents as English.
     * @type {Array<string>}
     * @memberof SearchRecordsRequest
     */
    textSearchLanguages: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchRecordsRequest
     */
    paginate?: boolean;
    /**
     * 
     * @type {Array<SearchRecordsRequestCursorInner>}
     * @memberof SearchRecordsRequest
     */
    cursor: Array<SearchRecordsRequestCursorInner>;
}

/**
 * Check if a given object implements the SearchRecordsRequest interface.
 */
export function instanceOfSearchRecordsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "recordGuids" in value;
    isInstance = isInstance && "textSearchLanguages" in value;
    isInstance = isInstance && "cursor" in value;

    return isInstance;
}

export function SearchRecordsRequestFromJSON(json: any): SearchRecordsRequest {
    return SearchRecordsRequestFromJSONTyped(json, false);
}

export function SearchRecordsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRecordsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'query': json['query'],
        'disableHighlighting': !exists(json, 'disable_highlighting') ? undefined : json['disable_highlighting'],
        'recordGuids': json['record_guids'],
        'textSearchLanguages': json['text_search_languages'],
        'paginate': !exists(json, 'paginate') ? undefined : json['paginate'],
        'cursor': ((json['cursor'] as Array<any>).map(SearchRecordsRequestCursorInnerFromJSON)),
    };
}

export function SearchRecordsRequestToJSON(value?: SearchRecordsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query': value.query,
        'disable_highlighting': value.disableHighlighting,
        'record_guids': value.recordGuids,
        'text_search_languages': value.textSearchLanguages,
        'paginate': value.paginate,
        'cursor': ((value.cursor as Array<any>).map(SearchRecordsRequestCursorInnerToJSON)),
    };
}

