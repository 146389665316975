/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResolveOrganisationResponse
 */
export interface ResolveOrganisationResponse {
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationResponse
     */
    stotlesId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationResponse
     */
    duns?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationResponse
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ResolveOrganisationResponse
     */
    countryCode?: string;
}

/**
 * Check if a given object implements the ResolveOrganisationResponse interface.
 */
export function instanceOfResolveOrganisationResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ResolveOrganisationResponseFromJSON(json: any): ResolveOrganisationResponse {
    return ResolveOrganisationResponseFromJSONTyped(json, false);
}

export function ResolveOrganisationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolveOrganisationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stotlesId': !exists(json, 'stotles_id') ? undefined : json['stotles_id'],
        'duns': !exists(json, 'duns') ? undefined : json['duns'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
    };
}

export function ResolveOrganisationResponseToJSON(value?: ResolveOrganisationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stotles_id': value.stotlesId,
        'duns': value.duns,
        'name': value.name,
        'country_code': value.countryCode,
    };
}

