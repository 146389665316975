import * as React from "react";
import {
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { captureException } from "@sentry/react";
import { Button, Dropdown, Modal, Spin } from "antd5";

import { AsyncRecordExportRequestFormatEnum } from "lib/generated/app-api/models/AsyncRecordExportRequest";
import { useDialogManager } from "lib/providers/DialogManager";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { downloadFileFromLink } from "lib/utils";

import css from "./AsyncExportButton.module.scss";

type AsyncProps = {
  onExport: (
    format: AsyncRecordExportRequestFormatEnum,
  ) => Promise<{ jobId: string; downloadUrl: string }>;
  exportedDataType: EventDataTypes;
  resultsCount: number;
};

function AsyncExportButton({ onExport, exportedDataType, resultsCount }: AsyncProps) {
  const dialogManager = useDialogManager();
  const { logEvent } = useTracking();

  const handleStartDownload = React.useCallback(
    (format: AsyncRecordExportRequestFormatEnum) => {
      return async () => {
        const { jobId, downloadUrl } = await onExport(format);
        logEvent(EventNames.exportedData, {
          Count: resultsCount,
          "Data type": exportedDataType,
          format,
        });
        const onFinish = () => {
          downloadFileFromLink(downloadUrl, `stotles_notice_export.${format}`);
        };

        void dialogManager.openDialog(DownloadModal, { jobId, onFinish });
      };
    },
    [onExport, logEvent, resultsCount, exportedDataType, dialogManager],
  );

  // I've kept these as menu items, when upgrading to react 4 the menuItems are all that need to be
  // passed on to the Dropdown. But in the `menu` prop instead of `overlay`
  const menu = React.useMemo(
    () => ({
      items: [
        {
          key: "xlsx",
          label: "Export to XLSX",
          onClick: handleStartDownload(AsyncRecordExportRequestFormatEnum.Xlsx),
        },
        {
          key: "csv",
          label: "Export to CSV",
          onClick: handleStartDownload(AsyncRecordExportRequestFormatEnum.Csv),
        },
      ],
    }),
    [handleStartDownload],
  );

  return (
    <Dropdown menu={menu}>
      <Button icon={<DownloadOutlined />}>
        Export ({resultsCount})
        <DownOutlined />
      </Button>
    </Dropdown>
  );
}

type ModalProps = {
  jobId: string;
  isOpen: boolean;
  onFinish: () => void;
  onClose: () => void;
};

// We block closing the download modal unless the user agrees to cancel the download
function DownloadModal({ jobId, onClose, isOpen, onFinish }: ModalProps) {
  const api = useStotlesApi();
  const [hasFailed, setHasFailed] = React.useState(false);

  // The max time until timeout will be ~ 10 mins with these settings
  const MAX_RETRIES = 300;
  const STATUS_CHECK_TIMEOUT_MS = 2000;

  const onCompleteJob = React.useCallback(() => {
    onFinish();
    onClose();
  }, [onFinish, onClose]);

  const onFailure = React.useCallback((errorMessage: string) => {
    captureException(errorMessage);
    setHasFailed(true);
  }, []);

  // JOB POLLING LOOP
  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    let isDone = false;
    let jobCheckRetries = 0;

    if (!api || !jobId) {
      return;
    }
    const tick = async () => {
      if (isDone) {
        return;
      }
      if (jobCheckRetries > MAX_RETRIES) {
        isDone = true;
        onFailure(`Async export with jobId ${jobId} timed out after 10 minutes`);
        return;
      }

      jobCheckRetries += 1;
      try {
        const job = await api.getJob(jobId);
        if (job.finished) {
          isDone = true;
          onCompleteJob();
          return;
        } else if (job.state === "failed") {
          onFailure(`Async export with jobId ${jobId} failed.`);
        }
        timeoutId = setTimeout(tick, STATUS_CHECK_TIMEOUT_MS);
      } catch (e) {
        onFailure(`Async export with jobId ${jobId} failed. Error message: ${e}`);
      }
    };
    void tick();
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [jobId, onCompleteJob, onFailure, api]);

  return (
    <Modal destroyOnClose maskClosable={hasFailed} closable={hasFailed} open={isOpen} footer={null}>
      <div className={css.exportModal}>
        {hasFailed ? (
          <>
            <ExclamationCircleOutlined className={css.errorIcon} />
            <div className={css.content}>
              <h1>Oh uh, something went wrong</h1>

              <p>Please try exporting again or contact a member of our team at team@stotles.com.</p>
            </div>
            <Button type="primary" onClick={onClose}>
              Ok
            </Button>
          </>
        ) : (
          <>
            <Spin indicator={<LoadingOutlined spin />} />

            <div className={css.content}>
              <h1>It's exporting....</h1>

              <p>
                We're currently exporting the data for you, but as it's a large export it might take
                a few minutes to complete.
              </p>

              <b>Please don't exit in the meantime, otherwise your export will be lost.</b>
            </div>
            <Button danger onClick={onClose}>
              Cancel Export
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}

export default AsyncExportButton;
