import * as React from "react";
import { FileOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Button, Modal } from "antd5";
import { ButtonType } from "antd5/es/button/buttonHelpers";

import { ButtonLink, ButtonLinkProps, TextLink } from "components/actions/Links";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useDialogManager } from "lib/providers/DialogManager";
import * as tracking from "lib/tracking";

// it's enough to check whether the source docs url `includes` one of these
const BRAVO_SOLUTIONS_PORTALS = [
  // this covers the majority
  "bravosolution.",
  // these are the less obvious ones, because their connection to bravo isn't explicit
  "contracts.mod.uk",
  "capitalesourcing.com",
  "nhssourcing.co.uk",
  "tenders.bris.ac.uk",
];
const BRAVO_SOLUTIONS_PUBLIC_OPPORTUNITIES_PATH = "/esop/guest/go/public/opportunity/current";

type Props = {
  url: string;
  linkType?: "text" | "button";
  buttonType?: ButtonType;
  showIcon?: boolean;
};

function SrcDocsLink({
  url: tenderUrl,
  linkType = "button",
  buttonType = "primary",
  showIcon,
}: Props): JSX.Element {
  const dialogManager = useDialogManager();
  const props: ButtonLinkProps = {
    to: tenderUrl,
    targetType: "external",
    // This is a little confusing but the icon file shows an ant icon and the disabling
    // of the showIcon param hides the external icon from ButtonLink
    showIcon: showIcon,
    eventName: tracking.EventNames.navToSourceDocs,
  };

  const isBravoPortal = BRAVO_SOLUTIONS_PORTALS.some((portal) => tenderUrl.includes(portal));
  const isBlpdPortal = tenderUrl.includes("https://www.blpd.gov.uk");

  if (isBravoPortal) {
    const urlOrigin = new URL(tenderUrl).origin;

    props.onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();
      const startUrl = urlOrigin + BRAVO_SOLUTIONS_PUBLIC_OPPORTUNITIES_PATH;
      const newWindow = window.open(startUrl, "_blank");

      setTimeout(() => {
        if (newWindow) {
          newWindow.location.href = tenderUrl;
        } else {
          Sentry.captureException(
            `cannot open source docs for ${tenderUrl} - newWindow not present`,
          );
        }
      }, 1500);
    };
  }

  if (isBlpdPortal && FeatureToggles.isEnabled(Feature.BLPD_PORTAL_WARNING)) {
    props.onClick = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();
      void dialogManager.openDialog(BlpdPortalWarning, {});
    };
  }

  if (linkType === "text") {
    return (
      <TextLink {...props} showIcon={showIcon}>
        {tenderUrl}
      </TextLink>
    );
  }

  return (
    <ButtonLink {...props} type={buttonType} icon={showIcon ? "" : <FileOutlined />}>
      See source documents
    </ButtonLink>
  );
}

type BlpdPortalWarningProps = {
  isOpen: boolean;
  onClose: () => void;
};

function BlpdPortalWarning({ isOpen, onClose }: BlpdPortalWarningProps): JSX.Element {
  return (
    <Modal
      open={isOpen}
      onOk={onClose}
      title="BLPD is currently unavailable and is being replaced"
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <p>
        Blue light commercial is currently working on a new procurement hub to replace the BLPD. The
        new system is expected to launch early in the New Year. In the meantime it will not be
        possible to access source documents for BLPD opportunities but any awards published before
        it was taken offline will remain available to view on Stotles.
      </p>
      <p>
        More information is available{" "}
        <TextLink
          to="https://bluelightcommercial.police.uk/suppliers/#:~:text=Blue%20Light%20procurement%20database%20(BLPD)"
          targetType="new-tab"
        >
          here
        </TextLink>
      </p>
    </Modal>
  );
}

export default SrcDocsLink;
